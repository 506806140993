import React, {useEffect, useState} from 'react'
import './CSS/Personalizar.css'
// import Personaliza from '../Components/Personaliza/Personaliza';
import { useParams } from 'react-router-dom';
import {get_customize, get_item} from '../Apis/Api';
import ImagesList from '../Components/Personalizar/ImagesList/ImagesList';
import MainImage from '../Components/Personalizar/MainImage/MainImage';
import ImageSizer from '../Components/Personalizar/ImageSizer/ImageSizer';
import useShop from '../Hooks/useShop'
import { all_images } from '../Apis/Api';
import { get_stock, coleccion_item, coleccion, coleccion_imagen } from '../Apis/Coleccion';
import Header from '../Components/Personalizar/Header';

const Personalizar = () => {
  const [colecciones, setColecciones] = useState([])
  const [actual_img, setActualImg] = useState(0)
  const [actual_col, setActualCol] = useState(0)
  const [reload, setReload] = useState(false)
  const [fondos, setFondos] = useState([])
  const {addToCart} = useShop()

  const {productId, size} = useParams();
  const lista_imagenes = all_images()
  let repaint = null;

  const setRefresh=(fn) => repaint = fn
  const hdlRefresh= async (last=false) =>  {
      let ct = Date.now()
      if (last) ct=ct*(-1)
      setReload(ct)
  }

  const hdlAdd = () => {
    const p = {}
    const i = get_stock(size)
    if (!i) return null;
    const product = coleccion_item.value
    p.id = product.id
    p.nombre = product.nombre
    p.stock_id = i.id
    p.talla = i.talla
    p.color = i.color
    p.sku = i.sku
    p.precio = i.precio
    p.oferta = i.oferta
    p.imagen = i.recursos?.imagen[0].url
    p.custom= coleccion.value.custom
    addToCart(p)
  }

  const handleSelection = (url, orden) => {
    const x = parseInt(orden)
    if (x) setActualImg(x)
  }
  const hdlPrintable = (src) => { coleccion_imagen.value = src }
  const hdlColeccion = (id, cols) => {
    const ims = []
    if (!cols) cols=colecciones
    for (const c of cols) {
      if (Number(c.id) === Number(id)) {
        setActualCol(id)
        const col = {...c}
        col.custom =  JSON.parse(col.custom)
        if (col?.custom) {
          for (const i of get_stock()?.recursos?.imagen) {
            if (col.custom?.keys && col.custom?.keys?.indexOf(i.orden)!==-1) ims.push(i)
          }
          setFondos(ims)
          if (ims.length) setActualImg(ims[0].orden)
          setReload(Date.now())
          for (const m in col.custom.mapa) {
            for (const q of col.custom.mapa[m]) { q.url="" }
          }
          coleccion.value = col
        }  
        break
      }
    }
  }

    useEffect(()=>{
      const fetch_data = async () => {
        coleccion_item.value = await get_item(productId);          
        const cols = await get_customize(coleccion_item.value?.id);
        setColecciones([...cols]);
        setReload(Date.now())
        if (cols && cols.length){ 
          hdlColeccion(cols[0].id, cols)
        }
      }
      fetch_data();
    },[productId]);

    if (!coleccion_item.value) return <></>;
    return (
      <div className='personalizar row'>
        <div className="col-6">
          <div className="row">            
            <Header cols={colecciones} onSelect={hdlColeccion} selected={actual_col} />
            <ImagesList layout="vertical" reload={reload} 
                lista={fondos} onSelected={handleSelection} />
            <MainImage refresh={setRefresh} reload={reload} index={actual_img}  /> 
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <ImageSizer onPrint={hdlRefresh} onAdd={hdlAdd} index={actual_img} title={coleccion_item.value?.nombre}  />
          </div>
        </div>
        <div className="wrowbox">
        <ImagesList layout="horizontal"  lista={lista_imagenes} onSelected={hdlPrintable} />
        </div>
      </div>      
    ) 
}

export default Personalizar
