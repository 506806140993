import React from 'react'
import './Section.css'
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";

const Section = ({onEdit, props}) => {
  const hdlRemove = (e) => {  }
  const hdlEdit = (e) => { onEdit(props)  }

  return (
    <div className='section-admin col-6'>
      <p className='bold'>{props.pagina}<FaRegTrashAlt data-idx={props.id} onClick={hdlRemove} className='icon-right'/></p>
      <p>{props.handle}</p>
      <p><FaRegEdit onClick={hdlEdit} data-idx={props.id} className='icon-right' /> </p>
    </div>
  )
}

export default Section
