import React, { useState } from 'react'
import './CSS/LoginSignup.css'
import { Link } from 'react-router-dom'
import EntryMsg from '../Components/EntryMsg/EntryMsg'
import useAuth from '../Hooks/useAuth'
import { AuthRequest, PublicRequest } from '../Apis/Axios'
import { useNavigate } from 'react-router-dom'
import Message from '../Components/Message/Message'
import { getLocal } from '../Apis/Api'

const LoginSignup = () => {
  const Auth = useAuth()
  const tema = getLocal('tema')
  const [fd, setFormData] = useState({
        nombre: "", email: "", telefono: "", genero: "femenino",  nacimiento: "2000-01-01",  password: ""
    })

  const [agree, setAgree] = useState(false);
  const navigate = useNavigate()      
  const [mensaje, setMensaje] = useState('')    
  const hdlChange = (event) => {
      setMensaje(null)       
      const {name, value} = event.target;
      let nuevo = {...fd}
      nuevo[name] = value
      setFormData({...nuevo})
  }

  const handleAgree = () => {
    setMensaje(null)       
    setAgree(!agree)
  }

  const handleUpdate = async () => {
      setMensaje(null)       
      if (!agree) {
        setMensaje("Debe de aceptar los términos y condiciones de uso.")
        return null;
      }
      const r = PublicRequest()
      const res = await r.post(`usuario`, {body:fd})
      if (res.ok) {
        const me = AuthRequest(res.data) 
        const usr = await me.fetch('usuario/me')
        if (usr.ok) {
          res.data.x_token=usr.data.uuid;
          res.data.user = usr.data
          // Saves user with token
          Auth.Login(res.data)
          navigate('/')
        } else setMensaje(usr.data)
      } else {
          setMensaje(res.data)
      }
  }

  if (Auth.isLogged()) return (
    <div className='loginsignup'>
      <div className="loginsignup-container">
        <h1>Registro</h1>
        <p>Actuelmente tiene una sesión abierta, favor de cerrarla para continuar.</p>
      </div>
      </div>
  )

  return (
    <div className='loginsignup row'>
    <div className="loginsignup-container col-6">
        <div className="row">
          <h1>Registro</h1>
        </div>
        <div className="row">
          <div className="loginsignup-fields col-12">
            <EntryMsg name="nombre" value={fd.nombre} required length={100} change={hdlChange}  msg={mensaje} />
            <EntryMsg name="email" value={fd.email} required length={100} change={hdlChange} msg={mensaje} />
            <EntryMsg name="telefono" holder="Celular" value={fd.telefono} required length={15} change={hdlChange} msg={mensaje} />
            <EntryMsg type='select' name="genero" options={["masculino","femenino","n/a"]}  
              value={fd.genero} required  change={hdlChange} msg={mensaje} />
            <EntryMsg type="date" name="nacimiento" holder="Fecha de Nacimiento" 
            value={fd.nacimiento} required length={15} change={hdlChange} msg={mensaje} />
            <EntryMsg name="password" value={fd.password} required length={20} type="password" change={hdlChange}  msg={mensaje} />
            <button className={'btn-lg btn-' + tema} onClick={handleUpdate}>Continuar</button>
          </div>

          <div className="loginsignup-login">
              ¿Ya tienes cuenta?<Link to='/login'><span>Ingrese aquí</span></Link>
              <div className="loginsignup-agree">
                <input checked={agree} onChange={handleAgree} type="checkbox" name="" id="" />
                <p>Al continuar aceptas los términos y condiciones.</p>
              </div>
          </div>          
          <Message tipo="error" mensaje={mensaje} />


        </div>
      </div>
    </div>
  )
}

export default LoginSignup
