import React, { useEffect, useState } from 'react'
import './CSS/Product.css'
import { useParams } from 'react-router-dom'
import Breadcrum from '../Components/Breadcrum/Breadcrum'
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay'
import DescriptionBox from '../Components/DescriptionBox/DescriptionBox'
import { get_item } from '../Apis/Api'

const Product = () => {
  const {productId} = useParams();
  const [product, setProduct] = useState(null);
  useEffect(()=>{
    const fetch_data = async () => {
        const data= await get_item(productId);
        if (data) setProduct(data);
    }
    fetch_data();
  },[productId]);

  if (!product) return <></>;
  return (
    <div className='product'>
      <Breadcrum product={product} />
      <ProductDisplay product={product} />
      <DescriptionBox ficha={product.ficha} />
      {/* <RelatedProducts/> */}
    </div>
  )
}

export default Product
