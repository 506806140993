import React, { useState } from 'react'
import './AddressList.css'
import AddressInfo from './AddressInfo';
import Address from './Address';
import { getLocal } from '../../Apis/Api';

const AddressList = (props) => {
  const {lista,me, onUpdate} = props;
  const [agregar, setAgregar] = useState(false);
  const tema = getLocal('tema')   

  const handleClick = () =>  setAgregar(true);
  const hdlCancel = () =>  setAgregar(false);
  const hdlUpdate = () => {
      hdlCancel()
      onUpdate()
  }

  const show = () => {
    if (!agregar) return <></>
    return <Address onCancel={hdlCancel} onUpdate={hdlUpdate} me={me} />
  }

  return (
    <div className='addresslist col-6'>
        <div className="row">
          <div className="col-12">
            <h1>Direcciones Activas</h1>        
            <button className={'btn-md btn-' + tema} onClick={handleClick} >Agregar</button> 
          </div>
          <div className="col-12">
            {show()}
            {lista.map((el, i)=>{
                return <AddressInfo onUpdate={onUpdate} data={el} key={i} />
              })
            }
          </div>
        </div>
    </div>
  )
}

export default AddressList
