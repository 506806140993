import React, { useContext, useState } from 'react'
import './ProductDisplay.css'
import { ShopContext } from '../../Context/ShopContext'
import { Link, useNavigate } from 'react-router-dom'
import SizeList from './SizeList'
import { getLocal } from '../../Apis/Api'

const ProductDisplay = (props) => {
  const {product} = props;
  const {addToCart, addSetting, getSetting} = useContext(ShopContext);
  const [csize, setCSize] = useState(getSetting('currentSize'))
  const navigate = useNavigate()
  const tema=getLocal('tema')
  
  const hdlSize = (size) => {
    addSetting('currentSize',size)    
    setCSize(size);
  }

  const  hdlAddCart = ()=> {
    for (const i of product.detalle) {
      if (i.talla===csize) {
        const p = {}
        p.id = product.id
        p.nombre = product.nombre
        p.stock_id = i.id
        p.talla = i.talla
        p.color = i.color
        p.sku = i.sku
        p.precio = i.precio
        p.oferta = i.oferta
        p.imagen = i.recursos?.imagen[0].url
        p.custom= null
        addToCart(p)
      }
    }
    navigate('/cart')
  }

  addSetting('currentProduct', product);
  const variante = product.detalle[0];

  if (csize) {
    let found=false
    for (const t of product.detalle) {
      if (t.talla===csize) found=true;
    }
    if (!found) hdlSize("");
  }

  if (product.detalle.length===1 && !csize) hdlSize(variante.talla)
  const colores = new Set();
  for (const c of product.detalle) {
    colores.add(c.base);
  }
  const tallas = new Set();
  for (const c of product.detalle) {
    if (c.stock<=0)  continue;
    tallas.add(c.talla);
  }
  let first = '';
    if (variante.recursos && variante.recursos.imagen) {
    first = variante.recursos.imagen[0].url;
    }
  const [main_image, setMainImage] = useState(first);

  return (
    <div className="productdisplay row">
      <div className="productdisplay-left col-6">
        <div className="row">
          <div className="productdisplay-img-list w-20">
            {variante.recursos.imagen.map((image, i) => {
                return <img key={i} src={image.url} onClick={()=>{setMainImage(image.url)}} alt="" />
            })}           
          </div>
          <div className="productdisplay-img w-80">
              <img className='productdisplay-main-img' src={main_image} alt="" />
          </div>
        </div>
      </div>

      <div className="productdisplay-right col-6">
        <div className="row">
          <h1>{product.nombre}</h1>
        </div>
        <div className="productdisplay-right-prices row">
            <div className="productdisplay-right-price-old w-20">
                Antes : ${variante.precio}
            </div>
            <div className="productdisplay-right-price-new w-20">
                ${variante.oferta}
            </div>
        </div>
        <div className="productdisplay-right-description row">
            {product.ficha.substring(0,200)}
        </div>

        <div className="productdisplay-right-color row">
            <div className="w-20">
              <h1>Elija Color</h1>
            </div>
            <div className="productdisplay-right-colors w-20">
            {Array.from(colores).map((c, i) => {
              return <div key={i}>{c}</div>
            })}                         
            </div>
        </div>

        <div className="productdisplay-right-size row">
            <div className="w-20">
              <h1>Elija Talla</h1>
            </div>
            <div className="w-80">
              <SizeList lista={tallas} choosen={csize} onSelect={hdlSize} />
            </div>
            <div className="row">
              <div className="w-50">
                <button className={'btn btn-'+tema} disabled={!csize} onClick={hdlAddCart}>Agregar</button>
              </div>
              <div className="w-50">
                <Link to={`/personalizar/${product.handle}/${csize}`}> 
                  <button className={'btn btn-'+tema} disabled={!csize}>Personalizar</button>
                </Link>
              </div>
            </div>
        </div>
      </div>      
    </div>
  )
}
 
export default ProductDisplay
