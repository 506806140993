import React from 'react'
import './Total.css'

export const  asMoney = (x) => {
  if (!x) return "";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Total = ({label, amount}) => {
  return (
    <div className="total-order row">
      <div className="w-50 bold flex-right">{label}</div>
      <div className="w-50 flex-right">$ {asMoney(amount)}</div>
    </div>
  )
}

export default Total
