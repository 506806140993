import React, {  useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './Navbar.css'
import cart_icon from '../Assets/cart_icon.png';
import Settings, { getLocal } from '../../Apis/Api';
import { FaUser } from "react-icons/fa";
import useShop from '../../Hooks/useShop';
import useAuth from '../../Hooks/useAuth';
import { saveLocal } from '../../Apis/Api';

const Navbar = () => {
  const [menu, setMenu] = useState("")
  const [logo, setLogo] = useState("")
  const [alias, setAlias] = useState("")
  const {resetSetting, getTotalCartItems} = useShop()
  const {isLogged, Logout} = useAuth()
  const [logged, setLogged] = useState(isLogged())
  const [tema, setTema] = useState("gray")

  useEffect(()=>{
    const fetch_data = async () => {
        const data= await Settings('config');
        if (data) {
            setLogo(data.logo);
            setAlias(data.alias);
            saveLocal('tienda', data.tienda_id)
            saveLocal('p-imagenes', data.imagenes)
            saveLocal('tema', data.tema)
            setTema(data.tema)
        }
    }
    fetch_data();
  },[]);

  useEffect(()=>{
    setLogged(isLogged() ? true : false);
  },[isLogged])

  const handleLogout = () => {
    setLogged(false)
    resetSetting()
    Logout()
  }

  const LogHeader = () => {
    if (!logged)  return <Link to='/login'><button>Login</button></Link>;
    return (
        <div className='navbar-logged'>
            <Link to='/account'><button><FaUser/></button></Link>
            <Link onClick={handleLogout} ><button>Logout</button></Link>
        </div>
    )   
  }

  return (<>
    <div className='hide-sm navbar'>
        <div className="row">
            <div className="nav-logo col-2">
                <Link style={{ textDecoration:'none'}} to='/'>
                    <img src={logo} style={{maxWidth:"120px"}} alt="" />
                    {/* <p>{alias}</p> */}
                </Link>
            </div>

            <div className="col-8">
                <ul className="nav-menu row">
                    <li className='col-4' onClick={()=>{setMenu("playeras")}}>
                        <Link style={{ textDecoration:'none'}} to='/playeras-y-polos'>Playeras y Polos</Link>{menu==="playeras"?<hr className={tema} />:<></>}
                    </li>
                    <li className='col-4' onClick={()=>{setMenu("sudaderas")}}>
                        <Link style={{ textDecoration:'none'}} to='/sudaderas-y-hoodies'>Sudaderas y Hoodies</Link>{menu==="sudaderas"?<hr className={tema} />:<></>}
                    </li>
                    <li className='col-4' onClick={()=>{setMenu("accesorios")}}>
                        <Link style={{ textDecoration:'none'}} to='/accesorios'>Accesorios</Link>{menu==="accesorios"?<hr className={tema} />:<></>}
                    </li>
                </ul>
            </div>      
            <div className="nav-login-cart col-2">
                <div className="row">
                    <div className="col-8">
                        {LogHeader()}
                    </div>
                    <div className='col-4'>
                        <Link to='/cart'><img src={cart_icon} alt="" />
                            <span className={"nav-cart-count-" + tema}>{getTotalCartItems()}</span>
                        </Link>
                    </div>

                </div>
            </div>
        </div>

    </div>

    {/* Mobile version */}

    <div className='only-sm navbar'>
        <div className="row">
            <div className="nav-logo w-25">
                <Link style={{ textDecoration:'none'}} to='/'>
                    <img src={logo} style={{maxWidth:"80px"}} alt="" />
                    {/* <p>{alias}</p> */}
                </Link>
            </div>

            <div className='w-50' onClick={()=>{setMenu("playeras")}}>
                <div className="row">
                    <div className="w-33 menu-sm">
                        <Link to='/playeras-y-polos'>Playeras</Link>{menu==="playeras"?<hr/>:<></>}
                    </div>
                    <div className="w-33 menu-sm" onClick={()=>{setMenu("sudaderas")}}>
                        <Link to='/sudaderas-y-hoodies'>Sudaderas</Link>{menu==="sudaderas"?<hr/>:<></>}
                    </div>
                    <div className="w-33 menu-sm" onClick={()=>{setMenu("accesorios")}}>
                        <Link to='/accesorios'>Accesorios</Link>{menu==="accesorios"?<hr/>:<></>}
                    </div>
                </div>
            </div>      
            <div className="nav-login-cart w-25">
                <div>
                    {LogHeader()}
                </div>
            </div>
        </div>
    </div>



    </>
  )
}

export default Navbar
