/* Axios, solicita diferente llamadas para cada caso */
import axios from 'axios'
import { saveLocal, getLocal, deleteLocal } from './Api';

const fetching_it={};
let SERVER = window.location.protocol + '://'+ window.location.host +'/';
if (SERVER.includes("3000")) SERVER="http://127.0.0.1:8000/api/"
else SERVER='/api/'

const getURL = (url) => {
    if (url?.toLowerCase().substring(0,4)==='http') return url;
    return SERVER+url;
}

const delay = ms => new Promise(res => setTimeout(res, ms));

const getSession = () => {
    const auth = getLocal('auth')    
    const token = auth?.x_token;
    // if (!auth) throw new Error("Usuario no authenticado");
    if (token) return {
            "X-Token" : token,
            "accept": "application/json",
            'Content-Type':'application/json'
        }
    return {"accept": "application/json",'Content-Type':'application/json'}
}


const getauth = (withAuth) => {
    const auth = withAuth ? withAuth : getLocal('auth')    
    const token = auth?.access_token;
    if (!auth) throw new Error("Usuario no authenticado");
    if (token) return {
            "Authorization" :"Bearer "+token,
            "accept": "application/json",
            "Content-Type":"application/json"
        }
    return {"accept": "application/json",'Content-Type':'application/json'}
}

export const PublicRequest = () =>  new Request(false)
export const AuthRequest = (auth=true) =>  new Request(auth)
export const SessionRequest = (auth="session") =>  new Request(auth)
export const FormRequest = () =>  new Request(false, false)

export class Request {
    constructor(auth=true, json=true) {
        this.auth=auth
        this.json=json
        this.status=0
        this.ok=false
        this.headers = null;
        if (auth && auth===true) {
            this.headers = getauth()
        } else if (auth && auth==="session") {
            this.headers = getSession()
        } else if (auth && auth!==true) {
            this.headers = getauth(auth)
        }
    }

    async get_current(config) {
        return  await axios.request(config)
        .then(response => {
            return {ok:true, status:response.status, data:response.data}
        }).catch(error => {
            console.log(error);
            return {ok:false, status:error.response.status, data:error.response.data}
        });        
    }

    async fetchIt(url, method,  props) {
        let counter = 0;
        while (fetching_it[url]) {
            await delay(100);
            if (counter===100) {
                fetching_it[url] = false;
                break;
            }
            counter++;
        }
        fetching_it[url] = true;

        const config = {            
            method:method.toUpperCase(),
            url: getURL(url),
        }
        if (props.params) config['params'] = props.params;
        if (props.body && this.json) config['data'] = props.body;
        if (props.body && !this.json) config['data'] = new URLSearchParams(props.body);
        if (props.headers || this.headers) {
            let h = {}
            if (this.headers) h = {...this.headers}
            if (props.headers) h = {...h, ...props.headers}
            config["headers"] = h
        }

        let current = await this.get_current(config)
        // Token ha expirado, se refrezca y se realiza de nuevo la llamada
        if (!current.ok && current.status===401) {
            const auth = getLocal('auth')    
            const token = auth?.refresh_token;
            const r = PublicRequest()
            const res = await r.post('refresh/'+token)
            if (res.ok) {
                auth.access_token = res.data.access_token
                auth.refresh_token = res.data.refresh_token
                saveLocal('auth', auth)
                config.headers["Authorization"] = "Bearer "+auth.access_token
                current = await this.get_current(config)
            } else {
                // Si no logra confirmar el token, debe hacer logout
                deleteLocal('auth')
                window.location.href = "/login";
            }
                    
        }
            
        fetching_it[url] = false;
        return current;
    }

    async post(url, props={}) {
        return await this.fetchIt(url, 'post', props)
    }
    async put(url,  props={}) {
        return await this.fetchIt(url, 'put', props)
    }
    async delete(url, props={}) {
        return await this.fetchIt(url, 'delete', props)
    }
    async patch(url, props={}) {
        return await this.fetchIt(url, 'patch', props)
    }
    async fetch(url, props={}) {
        return await this.fetchIt(url, 'get', props)
    }
}

