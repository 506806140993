import React from 'react'
import './Shipping.css'
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";



const Shipping = ({onEdit, props}) => {
    const valor = JSON.parse(props.valor)

  const hdlRemove = (e) => {  }
  const hdlEdit = (e) => { onEdit(props)  }

  return (
    <div className='shipping-admin col-6'>
      <p className='bold'>{valor.nombre}<FaRegTrashAlt data-idx={props.id} onClick={hdlRemove} className='icon-right'/></p>
      <p>{valor.descripcion}</p>
      <p>Hasta:{valor.hasta} Kgs, ${valor.precio} <FaRegEdit onClick={hdlEdit} data-idx={props.id} className='icon-right' /> </p>
    </div>
  )
}


export default Shipping
