import React, { useState, useContext } from 'react'
import './SizeList.css'
import Size from './Size';
import { ShopContext } from '../../Context/ShopContext';


const SizeList = ({lista, choosen, onSelect}) => {
    const [selected, setSelected] = useState(choosen);
    const handleClick = (evt) => {
      setSelected(evt.target.dataset.size)
      onSelect(evt.target.dataset.size)
    }

  return (
    <div className='sizelist'>
        {Array.from(lista).map((s, i) => {
            return <Size onClick={handleClick} selected={selected} size={s} key={i}/>
        })}                               
    </div>
  )
}

export default SizeList
