import React, { useState } from 'react'
import './CollectionHeader.css'
import EntryMsg from '../../../Components/EntryMsg/EntryMsg';
import { coleccion } from '../../../Apis/Coleccion';
import { getLocal } from '../../../Apis/Api';

const CollectionSingle = ({onCancel, onUpdate, onError, }) => {
    const [data, setData] = useState(coleccion.value)
    const tema = getLocal('tema')

    const hdlChange = (e) =>{
        const {name, value} = e.target;
        coleccion.value[name] = value
        data[name] = value
        setData({...data})
    }

    const hdlCustom = (e) =>{
        const {name, value} = e.target;
        coleccion.value.custom[name] = value
        data.custom[name] = value
        setData({...data})
    }

    const hdlContinue = () => { onUpdate()}
    
  return (
    <div className='collectionheader row'>
        <div className="collectionform-input w-100">
            <EntryMsg name="coleccion" value={coleccion.value.coleccion} length={120}  label="Coleccion" change={hdlChange} />
        </div>
        <div className="collectionform-input w-100">
            <EntryMsg name="etiquetas" value={coleccion.value.etiquetas} length={500}  label="Etiquetas" change={hdlChange} />
        </div>

        <div className="collectionform-input w-100">
            <EntryMsg name="titulo" value={coleccion.value.titulo} length={120}  label="Título" change={hdlCustom} />
        </div>

        <div className="collectionform-input w-100">
            <EntryMsg name="subtitulo" value={coleccion.value.subtitulo} length={120}  label="Subtítulo" change={hdlCustom} />
        </div>

        <div className="collectionform-input w-100">
            <EntryMsg name="banner" value={coleccion.value.banner} length={500}  label="Banner" change={hdlCustom} />
        </div>

        <div className="row flex-center">
            <button onClick={onCancel} className={'btn btn-'+tema}>Cancelar</button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={hdlContinue} className={'btn btn-'+tema}>Actualizar</button>
        </div>
    </div>
  )
}

export default CollectionSingle
