import React from 'react'
import './EntryMsg.css'
import Message from '../Message/Message';

const Title = (cadena) => {
  if (!cadena) return ''
  if (!(typeof cadena === 'string' || cadena instanceof String)) return '';
  return cadena.charAt(0).toUpperCase() + cadena.slice(1);
}  

const EntryMsg = ({value, type, msg, name, change, holder, length, required, options, label, cols}) => {
    const tipo = type?type:"text";
    const largo = length===undefined?-1:length;
    const hold = holder?holder: Title(name)
    const choices = options ? options : []
    const rows = length<1000 ? Math.ceil(length/cols) : 15

  const show = () => {
    switch (tipo) {
      case "select":
        // console.log(choices);
        return (<>
          <label>{hold}</label>
          <select name={name} value={value} onChange={change}>
            { choices.map((v,i)=>{ return <option key={i} value={label ? v[label] : v}>{Title(v)}</option> })}
          </select>
        </>)

      case "date":
        return (<>
          <label>{hold}</label>
          <input required={required} value={value} name={name} onChange={change} type={tipo} />
          <Message tipo='error-key' field={name} mensaje={msg} />
        </>)
        
      case "area":
        return (<>
          <label>{hold}</label>
          <textarea rows={rows} cols={cols} required={required} value={value} name={name} onChange={change}></textarea>
          <Message tipo='error-key' field={name} mensaje={msg} />     
        </>)
          
      default:
        if (label) 
          return (<>
          <div className="entry-full">
              <label>{label}:</label>
              <input required={required} value={value} name={name} onChange={change} type={tipo} 
              maxLength={largo} placeholder={hold}/>
              <Message tipo='error-key' field={name} mensaje={msg} />     
            </div>
          </>)

        else 
          return (<>
            <input required={required} value={value} name={name} onChange={change} type={tipo} 
            maxLength={largo} placeholder={hold}/>
            <Message tipo='error-key' field={name} mensaje={msg} />     
          </>)
    }
  }

  return (
    <div className='entrymsg'>{show()}</div>
  )
}

export default EntryMsg
