import React from 'react'
import './OrderAdmin.css'
import { useState } from 'react'
import { STATUS } from '../../Apis/Api'
import EntryMsg from '../EntryMsg/EntryMsg'
import Message from '../Message/Message'
import { AuthRequest } from '../../Apis/Axios'

const OrderAdmin = ({data, onRefresh}) => {
    const [comment, setComment] = useState("")
    const [estado, setStatus] = useState("")
    const [publico, setPublico] = useState(false)
    const [msg, setMsg] = useState("")

    const chgStatus = (e) => {
        const value =e.target.value;
        setStatus(value);
    }

    const chgComment = (e) => {
        const value =e.target.value;
        setComment(value);
    }

    const hdlCheck = (e) => {
        setPublico(document.getElementById('publico').checked);
    }

    const hdlUpdate = async () => {
        if (!comment) {
            setMsg("Se requiere un comentario.");
            return null;
        }
        const res = AuthRequest()
        const url = `pedido/${data.tienda_id}/${data.id}`;
        const body = {
            comment, status:estado, publico: publico?1:0
        }
        const r = await res.put(url, {body} )
        if (r.ok) {
            setComment("")
            setPublico(false)
            setStatus("-- Seleccione --")
            onRefresh()
        } else {
            setMsg(r.data)
        }
    }


  return (
    <>
    <div className="hide-sm">
        <div className='orderadmin row'>
        <div className="w-25">
            <EntryMsg type="select" change={chgStatus} holder="Status" value={estado}  options={['-- Seleccione --', ...STATUS]} name="status" />
        </div>
        <div className="w-50">
            <EntryMsg name="comment" length={500} value={comment} change={chgComment} holder="Comentario" />
        </div>
        <div className="w-5">
            <input type="checkbox" onChange={hdlCheck} name="publico" id="publico" checked={publico} /> 
        </div>
        <div className="w-10">Público</div>
        <div className="w-10">
            <button onClick={hdlUpdate} className='btn'>Actualizar</button>
        </div>      
            <Message tipo="error" Mensaje={msg} />
        </div>
    </div>

    <div className="only-sm">
        <div className='orderadmin row'>
            <div className="w-50">
                <EntryMsg type="select" change={chgStatus} holder="Status" value={estado}  options={['-- Seleccione --', ...STATUS]} name="status" />
            </div>
            <div className="w-50">
                <EntryMsg name="comment" length={500} value={comment} change={chgComment} holder="Comentario" />
            </div>
        </div>
        <div className='orderadmin row'>
            <div className="w-25">
                <input type="checkbox" onChange={hdlCheck} name="publico" id="publico" checked={publico} /> 
            </div>
            <div className="w-25">Público</div>
            <div className="w-50">
                <button onClick={hdlUpdate} className='btn'>Actualizar</button>
            </div>      
        </div>
        <Message tipo="error" Mensaje={msg} />
    </div>

    </>
  )
}

export default OrderAdmin
