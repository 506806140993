import React from 'react'
import './Offers.css'
import exclusive_image from '../Assets/exclusive_image.png'
import { getLocal } from '../../Apis/Api'
import { Link } from 'react-router-dom'

const Offers = () => {
  const tema=getLocal('tema')
  return (
    <div className={'offers row hero-'+tema}>
      <div className="offers-left col-6">
        <h1>Ofertas</h1>
        <h1>Exclusivas</h1>
        <p>Solamente de lo más vendido</p>
        <Link to='/category/ofertas'>
          <button className={'btn-'+tema}>Ver Ahora</button>
        </Link>
      </div>
      <div className="offers-right col-5 hide-sm">
        <img src={exclusive_image} alt="" />
      </div>
    </div>
  )
}

export default Offers
