import { signal } from '@preact/signals-react';
import { AuthRequest } from './Axios';

export const coleccion = signal({ coleccion:"", etiquetas:"", enabled:1, id:0,
custom:{ product_id:0, color:"red", border:"black", mapa:{}, keys:[] }
});
export const coleccion_item = signal({});
export const coleccion_actual = signal(0);
export const coleccion_imagen = signal("");
export const preferencia = signal(null);
export const apagar = signal(0.0);

export const  get_stock = (size) => {
    const variantes = coleccion_item.value?.variantes 
        ? coleccion_item.value?.variantes 
        : coleccion_item.value?.detalle

    if (!variantes) return null
    if (size) {
        for (const v of variantes) {
            if (v.talla === size) return v
        }
    }
    else {
        if ( variantes) return variantes[0]
    }

    return null;
  }
  
export  const get_image = (idx) => {    
   if (!idx) return "";
   if (!get_stock() || !get_stock().recursos.imagen.length) return "";
   for (const im of get_stock().recursos?.imagen) {
       if (Number(im.orden)===Number(idx))  return im.url;
   }
   return "";
}

export const draw = async (quads, idx, q, last) => {
   const src = get_image(idx);
   const lista = quads.custom.mapa[idx]? [...quads.custom.mapa[idx]]  : []
   let found=false;
   if (q) {
    for (const c of lista) {
        if (q.nombre===c.nombre) found=true        
    }
    if (!found && q.nombre && q.x && q.w) lista.push(q)
   }
   
   const res = AuthRequest()    
   if (!lista || !lista.length) return;
   const body = {
       url:src,
       color:quads.custom.color,
       border:quads.custom.border,
       lista:lista
   }
   if (!src) return false
   const url = last ? 'draw?last=1' : 'draw?last=0'
   const r = await res.post(url, {body})
   if (r.ok)  return r.data
   return false
 }


 export const get_quad = (quads, name) => {
    for (const q of quads) {
        if (q.nombre===name) return q
    }
    return null
 }
