import React, {useState} from 'react'
import './Dominio.css'
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";

const Dominio = ({onRemove, props}) => {
  const [data, setData] = useState({...props})
  const hdlRemove = (e) => { onRemove(e.target.dataset.id)  }

  return (
    <div className='dominio col-6'>
        <p>{data.nombre}<FaRegTrashAlt data-id={data.id} onClick={hdlRemove} className='remove' /></p>
    </div>
  )
}

export default Dominio
