import React, { useEffect, useState } from 'react'
import './SectionList.css'
import Section from './Section'
import { AuthRequest } from '../../Apis/Axios'
import useAuth from '../../Hooks/useAuth'
import { forbidden } from '../../Components/RequireAuth/RequireStaff'
import SectionForm from './SectionForm'
import { Link } from 'react-router-dom'
import { getLocal } from '../../Apis/Api'

const SectionList = () => {
    const {getStore, getStoreName} = useAuth()
    const [store, setStore] = useState(getStore())        
    const [msg,setMsg] = useState('')
    const [lista, setLista] = useState([])
    const [edit, setEdit] = useState(0)
    const tema = getLocal('tema')

    const fetch_data = async ()=>{    
        const res = AuthRequest()
        const url= `utils/pagina/${store.id}`
        const r = await res.fetch(url)
        if (r.ok) {
            setLista(r.data.rows)
        } else setMsg(r.data)
     }
    
    useEffect(()=>{
        fetch_data()
    },[])

    const hdlEdit = (elem) => setEdit(elem)
    const hdlRefresh = () => {
        setEdit(0);
        fetch_data();
    }
    const hdlCancel = () => {setEdit(0)}
    const hdlNew = () => {
        const p = {id:0, "pagina":"", "handle":"", "contenido":"", enabled:1}
        setEdit(p)
    }
    const show = () => {
        if (!edit) return <></>
        return <SectionForm onRefresh={hdlRefresh} props={edit} />
    }
    if (!store) 
    return forbidden("No ha seleccionado la tienda","Es necesario seleccionar una tienda para xontinuar","/admin")

  return (
    <div className={'sectionlist row back-'+tema}>
        <h1>Contenido {getStoreName()}</h1>
        <button onClick={hdlCancel} className={'btn flex-right btn-'+tema} >Cancelar</button>
        <button onClick={hdlNew} className={'btn flex-right btn-'+tema} >Agregar</button>
        {show()}
        <div className="sectionlist-container row">
        {
            lista.map((elem, idx)=>{
                return <Section onEdit={hdlEdit}  props={elem} key={idx}/>
            })
        }
        </div>
        <Link to="/admin">Volver Administrador</Link>
    </div>
  )
}

export default SectionList
