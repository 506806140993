import React from 'react'
import './CollectionCuadrante.css'
import { FaRegTrashAlt } from "react-icons/fa";

const CollectionCuadrante = ({data, onSelect, onDelete}) => {

  const hdlRemove = (e) => {
    e.preventDefault();
    onDelete(data.nombre)
  }
  const hdlSelect = (e) => {
    e.preventDefault();
    onSelect(data.nombre)
  }

  if (!data?.nombre) return <></>

  return (
    <div className='collection-cuadrante col-3' onClick={hdlSelect} >
        <p>Cuadrante:{data.nombre}<FaRegTrashAlt data-idx={data.nombre} onClick={hdlRemove} className='icon-right'/></p>
        <p>{data.tipo}</p>
        <p>({data.x}, {data.y}) : w {data.w} </p>
        
    </div>
  )
}

export default CollectionCuadrante
