import React from 'react'
import Item from './Item'
import './ItemList.css'
import { getLocal } from '../../../Apis/Api'

const ItemList = () => {
  const tema = getLocal('tema')
  const items = getLocal("cart-now")
  return (
    <div className={'itemlist row back-'+tema}>
      {
        items.map((e,i) => {
            return <Item data={e} key={i} />
        })
      }
    </div>
  )
}

export default ItemList
