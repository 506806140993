import React, { useEffect, useState } from 'react'
import './Setting.css'
import Settings, { getLocal } from '../../Apis/Api'
import EntryMsg from '../../Components/EntryMsg/EntryMsg'
import { AuthRequest } from '../../Apis/Axios'
import { Link } from 'react-router-dom'

const Setting = () => {
    const [data, setData] = useState(null)
    const [msg, setMsg] = useState('');
    const [label, setLabel] = useState("Listo")
    const [idx, setIdx] = useState(0)
    const tienda = getLocal('store').id
    const tema = getLocal('tema')

  useEffect(()=>{
    const fetch_data = async () => {

      const res = AuthRequest();
      const url = `utils/setting/${tienda}/config/store`;
      const r = await res.fetch(url);
      if (r.ok) {
        setIdx(r.data.id)
        r.data.valor = JSON.parse(r.data.valor)
        if (!r.data.valor) r.data.valor={}
        return setData(r.data.valor);
      }

      return null;
    }
    fetch_data();
  },[]);


  const hdlChange = (event) => {
    setMsg(null)       
    setLabel("Actualizar")
    const {name, value} = event.target;
    let nuevo = {...data}
    nuevo[name] = value
    setData({...nuevo})
}

  const rules = [
    {name:"empresa", length:120, required:true}, 
    {name:"alias", length:50, required:true} , 
    {name:"rfc", length:15, required:false} , 
    {name:"telefono", length:15, required:true},  
    {name:"email", type:"email", length:50, required:true},  
    {name:"direccion",length:120, required:true},  
    {name:"colonia", length:120, required:true}, 
    {name:"ciudad", length:120, required:true}, 
    {name:"cp",type:"number", holder:"Código Postal", required:true}, 
    {name:"banner",length:500, required:false} , 
    {name:"logo",length:500, required:true},  
    {name:"tema",length:10, required:false, label:"tema", holder:"black,blue,gray,green,red,purple"},  
    {name:"imagenes",holder:"Imágenes", length:5000, required:false} , 
    {name:"iva",type:"number", required:true}, 
    {name:"whatsapp", length:120},
    {name:"instagram", length:120},
    {name:"pinterest", length:120},
    {name:"twitter", length:120},
    {name:"facebook", length:120}
]
 
  const getEntry = (props, idx) => {
    const value = data[props.name]
    props.msg = msg
    props.value = value
    props.change = hdlChange
    if (!props.label) props.label = props.holder ? props.holder : props.name;
    return <EntryMsg key={idx} {...props} />
  }
  
  const handleUpdate = async () => {
    const res = AuthRequest()
    const valor = {...data}
    const body = {grupo:"config", clave:"store", "valor":JSON.stringify(valor)}
    let r=null;
    if (idx) {
      delete valor.tienda_id;
      r = await res.put(`utils/setting/${tienda}`,{body:body})
    } else {
      r = await res.post(`utils/setting/${tienda}`,{body:body})
    }
    if (r.ok) {
        setLabel("Listo")
    } else setMsg(r.data)    
  }

  if (!data)  return <></>;

  return (
    <div className={'settings back-'+tema}>
        <div className="row"><h1>Configuración de Tienda</h1></div>
        <div className="col-8 settings-container">
        {
            rules.map((el, i)=>{
                if (el?.type!=="list") return getEntry(el, i);
            })
        }      
        <button className={'btn-md btn-'+tema} onClick={handleUpdate}>{label}</button>
        <br/>
        <Link to="/admin">Volver Administrador</Link>
        </div> 

    </div>
  )
}

export default Setting
