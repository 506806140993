import React, { useEffect, useState } from 'react'
import CartItems from '../Components/CartItems/CartItems'
import { PublicRequest } from '../Apis/Axios'
import { getLocal } from '../Apis/Api'
import { fetch_shipping, fetch_payment } from '../Apis/Api'

const Cart = () => {
  const [payment,setPayment] = useState([])
  const [shipping,setShipping] = useState([])
  const [cp, setCP] = useState(getLocal('cp'))

  const get_data = async (c) => {    
    setPayment(await fetch_payment())
    setShipping(await fetch_shipping(c))
  }

  useEffect(()=>{
    get_data(cp)
  },[])

const hdlQuote = async (c) => {
  setCP(Number(c))
  setShipping(await fetch_shipping(Number(c)))
}

  return (
    <div>
      <CartItems onQuote={hdlQuote} payments={payment} shippings={shipping} />
    </div>
  )
}

export default Cart
