import { Outlet, Link } from 'react-router-dom'
import useAuth from '../../Hooks/useAuth'
import './RequireStaff.css'
import { getLocal } from '../../Apis/Api'


export const forbidden = (error, comment, url) => {
    const tema = getLocal('tema')
    return (
        <div className={'forbidden row back-'+tema}>
            <div className=' forbidden-container col-6'>
            <h1>{error}</h1>
            <p>{comment}</p>
            <p><Link to={url} >Clic para volver</Link></p>
            </div>
        </div>
    )
}

const RequireStaff = () => {
    const {isStaff, toUrl}  = useAuth();
 
    return (
        isStaff()
            ? <Outlet />
            : forbidden("Error 403: Acceso no Autorizado","Por el momento no tiene acceso a esta sección","/")
     )
}

export default RequireStaff
