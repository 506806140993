import React, { useEffect, useState } from 'react'
import './User.css'
import { FaRegTrashAlt } from 'react-icons/fa'
import { AuthRequest } from '../../Apis/Axios'

const User = ({onDelete, store, props}) => {
  const clases = props.usuario_enabled ? "usuario col-4" : "usuario inactive col-3"

  const hdlDelete = async (e) => {
    const res = AuthRequest()
    const url = `usuario/${store.id}/${props.usuario_id}`;
    const r = await res.delete(url)
    if (r.ok) onDelete()
  }

  return (
    <div className={clases}>
        <p>{props.usuario}<FaRegTrashAlt onClick={hdlDelete} className='remove' /> </p>
        <p>{props.email}</p>
        <p>{props.rol}</p>
    </div>
  )
}
 
export default User
