import React from 'react'
import './Message.css'

const Message = (props) => {
  const {tipo, mensaje, field} =props;
  let data = null
  if (!mensaje) return <></>;
  if (field) {
    data=[]
    if (mensaje.detail && mensaje.detail[field]) data.push(mensaje.detail[field])
    if (Array.isArray(mensaje.detail)) {
      for (let i = 0; i < mensaje.detail.length; i++) {
        const e = mensaje.detail[i];
        if (e[field]) data.push(e[field])         
      }
    }
  } else {
    if (mensaje.detail) {
      if (typeof mensaje.detail === 'string')  data=[mensaje.detail];
    } else {
      if (typeof mensaje === 'string')  data=[mensaje];
    }
  }
      
  const lista = (data) => {
    return <ul>
        {data.map((line, idx)=>{
            return <li key={idx}>{JSON.stringify(line)}</li>
          })
        }
    </ul>
  }

  if (!data || !data.length) return <></>;
  const show = () => {
    switch (tipo) {
        case 'error-key':
        return  (<div className='message-errorkey'>{lista(data)}</div>);
        case 'error':
            return  (<div className='message-error'>{lista(data)}</div>);
        case 'success':
                return  (<div className='message-success'>{lista(data)}</div>);
        default:
            return  (<div className='message-regular'>{lista(data)}</div>);
    }
  }

  return (
    <div className={ field?"message-light":"message"}>
      {show()}
    </div>
  )
}

export default Message
