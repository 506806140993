import React, { useEffect, useState } from 'react'
import './PaymentWith.css'
import Payment from './Payment'
import { fetch_payment, getLocal, saveLocal } from '../../../Apis/Api'

const PaymentWith = () => {
    const [selected, setSelected] = useState(getLocal('payment'))
    const [lista, setLista] = useState([])
    const tema = getLocal('tema')
    const hdlSelect = (e) => {
      setSelected(e)
      saveLocal('payment', e)
    }

    const fetch_data = async () => setLista(await fetch_payment())
    useEffect(()=>{ fetch_data() },[])

  return (
    <div className={'paymentwith row back-'+tema}>
      <h1>Método de Pago</h1>
        {
            lista.map((e,i)=>{
                return <Payment  onSelect={hdlSelect} selected={selected} data={e} key={i} />
            })
        }
    </div>
  )
}

export default PaymentWith
