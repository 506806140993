import React from 'react'
import './DescriptionBox.css'

const DescriptionBox = (props) => {
  let ficha = props.ficha.replace(/(?:\r\n|\r|\n)/g, '<br>');
  return (
    <div className='descriptionbox row'>
        <div className="descriptionbox-navigator">
            <div className="descriptionbox-nav-box">Description</div>
            {/* <div className="descriptionbox-nav-box fade">Reviews (122)</div>          */}
        </div>
        <div className="descriptionbox-description">          
          <div dangerouslySetInnerHTML={{__html: ficha}} />
        </div>
      
    </div>
  )
}
export default DescriptionBox
