import React, {useState} from 'react'
import './Address.css'
import EntryMsg from '../EntryMsg/EntryMsg';
import { AuthRequest } from '../../Apis/Axios';
import { getLocal } from '../../Apis/Api';

const Address = (props) => {
    const {onUpdate, onCancel} = props
    const [fd, setFormData] = useState({
            controlador: "usuarios",  registro: 0,
            alias: "", calle: "", no_interior: "",
            no_exterior: "",  colonia: "",  cp: 0,
            entrecalles: "",  referencia: "",
            empresa: "", contacto: "", telefono: ""
        })

    const tema = getLocal('tema')
    const [mensaje, setMensaje] = useState('')    

    const hdlChange = (event) => {
        const {name, value} = event.target;
        let nuevo = {...fd}
        nuevo[name] = value
        setFormData({...nuevo})
    }

    const handleUpdate = async () => {
        setMensaje(null)        
        const r = AuthRequest()
        const res = await r.post(`usuario/direccion`, {body:fd})
        if (res.ok) { 
            onUpdate()
        } else {
            // console.log(res);
            setMensaje(res.data)
        }
    }
  return (
    <div className='address'>
        <div className="address-container">
            <h4>Nueva</h4>
            <div className="address-fields">
                <EntryMsg name="alias" msg={mensaje} change={hdlChange} value={fd.alias} length="30"/>
                <EntryMsg name="calle" msg={mensaje} change={hdlChange} value={fd.calle} length="120"/>
                <EntryMsg name="no_interior" msg={mensaje} change={hdlChange} value={fd.no_interior} holder="#Int" length="15"/>
                <EntryMsg name="no_exterior" msg={mensaje} change={hdlChange} value={fd.no_exterior} holder="#Ext" length="15"/>
                <EntryMsg name="colonia" msg={mensaje} change={hdlChange} value={fd.colonia} length="120"/>
                <EntryMsg name="cp" type="number" msg={mensaje} change={hdlChange} value={fd.cp} length="5"/>
                <EntryMsg name="entrecalles" msg={mensaje} change={hdlChange} value={fd.entrecalles} length="120"/>
                <EntryMsg name="referencia" msg={mensaje} change={hdlChange} value={fd.referencia} length="120"/>
                <EntryMsg name="empresa" msg={mensaje} change={hdlChange} value={fd.empresa} length="120"/>
                <EntryMsg name="contacto" msg={mensaje} change={hdlChange} value={fd.contacto} length="120"/>
                <EntryMsg name="telefono" msg={mensaje} change={hdlChange} value={fd.telefono} length="15"/>
            </div>
            <button className={'btn btn-'+tema} onClick={handleUpdate} >Aceptar</button>
            <button className={'btn btn-'+tema} onClick={onCancel} >Cacelar</button>
        </div>
    </div>
  )
}

export default Address
