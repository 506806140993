import React, { useState } from 'react'
import './ShippingForm.css'
import EntryMsg from '../../Components/EntryMsg/EntryMsg';
import Message from '../../Components/Message/Message';
import { AuthRequest } from '../../Apis/Axios';
import useAuth from '../../Hooks/useAuth';
import { getLocal } from '../../Apis/Api';

const ShippingForm = ({onRefresh, props}) => {    
    const valor = JSON.parse(props.valor)  
    const {getStore} = useAuth()
    const [label, setLabel] = useState(props.id?'Actualizar':'Agregar')
    const [msg, setMsg] = useState('')
    const [data, setData] = useState(valor);
    const [clave, setClave] = useState(props.clave);
    const [store, setStore] = useState(getStore())
    const tema = getLocal('tema')

    const hdlChange = (e) => {
        setMsg("")
        const {name, value} = e.target;        
        if (name==='adicional_p') data.adicional.precio = value
        else if (name==='adicional_a') data.adicional.ampara = value
        else if (name==='municipios') data.zona.municipios = value.split(",")
        else if (name==='excepto') data.zona.excepto = value.split(",")
        else if (name==='adicional_a') data.zona.excepto = value
        else  data[name] = value
        setData({...data})
    }
    const hdlClave = (e) => setClave(e.target.value)

    const hdlAdd = async () => {
        const res = AuthRequest()
        const url = `utils/setting/${store.id}`
        let r = null;
        props.valor = JSON.stringify(data)
        if (!props.id) {
            props.clave = clave
            r = await res.post(url, {body:props})
        } else  r = await res.put(url, {body:props})
        if (r.ok) {
            onRefresh()
        } else setMsg(r.data)
    }

  return (
    <div className='shippingform'>
        <div className="shippingform-container row">
        <EntryMsg msg={msg} change={hdlClave} name="clave" value = {clave} length={20} required label="Clave"/>
        <EntryMsg msg={msg} change={hdlChange} name="nombre" value = {data.nombre} length={100} required label="Nombre"/>
        <EntryMsg msg={msg} change={hdlChange} name="descripcion" value = {data.descripcion} length={500} required 
          type="area" cols={100}  label="Descripcion"/>
        <EntryMsg msg={msg} change={hdlChange} type="number" name="hasta" value = {data.hasta} length={5} required label="Hasta Kgs"/>      
        <EntryMsg msg={msg} change={hdlChange} type="number" name="precio" value = {data.precio} length={5} required label="Precio"/>      
        <EntryMsg msg={msg} change={hdlChange} type="number" name="adicional_p" value = {data.adicional.precio} length={5}  label="Adicional"/>      
        <EntryMsg msg={msg} change={hdlChange} type="number" name="adicional_a" value = {data.adicional.ampara} length={5}  label="Ampara+"/>      
        <EntryMsg msg={msg} change={hdlChange} name="municipios" value = {data.zona.municipios.join()} length={500} required 
          type="area" cols={100}  label="Municipios a incluir"/>
        <EntryMsg msg={msg} change={hdlChange} name="excepto" value = {data.zona.excepto.join()} length={200} required 
          type="area" cols={100}  label="Excluye CPs"/>
        </div>
        <div className="flex-center">
          <button onClick={hdlAdd} className={'btn-lg btn-'+tema}>{label}</button>
            <Message msg={msg} tipo="error" />
        </div>
        <hr />
    </div>
  )
}

export default ShippingForm
