import React, { useState } from 'react'
import './ShippingTo.css'
import useShop from '../../../Hooks/useShop'
import { getLocal } from '../../../Apis/Api'

const ShippingTo = ({data, onSelect, selected}) => {
    const tema=getLocal('tema')
    const {id, alias, calle , no_interior, no_exterior, cp, zona} = data
    const hdlSelect = () => onSelect(data)
    const clase = (selected && selected.id===data.id) ? 'shippingto selected ' : 'shippingto'

  return (
    <div className={clase} data-id={id} onClick={hdlSelect} >
      <div className='alias'>{alias}</div>
      <div>{calle} Int:{no_interior} Ext: {no_exterior}, CP:{cp}  </div>
      <div>{zona?.municipio}, {zona?.estado}; {zona?.pais} </div>

    </div>
  )
}

export default ShippingTo
