import React, { useState, useEffect } from 'react'
import './CSS/ShopCategory.css'
import Item from '../Components/Item/Item'
import { get_collection } from '../Apis/Api'
import { useParams } from 'react-router-dom'

const ShopCategory = (props) => {
  const categoryId = useParams()
  const [data_product, setItems] = useState([]);
  let banner = props.banner;
  if (!banner) banner='';
  useEffect(()=>{
    const fetch_data = async () => {
        if (categoryId.categoryId) {
          const data= await get_collection(categoryId.categoryId);
          if (data) setItems(data);
        } else {
          const data= await get_collection(props.category);     
          if (data) setItems(data);
        }
    }
    fetch_data();
  },[props.category, categoryId]);

  return (
    <div className='shop-category row'>
      <div className="col-12">
        <img className='shopcategory-banner' src={banner} alt="" />      
        <hr />
      </div>
      <div className="shopcategory-products row">
        {data_product.map((item, i) => {
          return <Item key={i} data={item} />
        })}
      </div>
    </div>
  )
}

export default ShopCategory
