import React from 'react'
import './Collection.css'
import { FaRegTrashAlt } from 'react-icons/fa'
import { AuthRequest } from '../../Apis/Axios'
import { Link } from 'react-router-dom'

const Collection = ({onDelete, store, props}) => {
  const clases = props.enabled ? "collection-container" : "collection-container inactive"

  const hdlDelete = async (e) => {
    const res = AuthRequest()
    const url = `utils/coleccion/${store.id}/${props.id}`;
    const r = await res.delete(url)
    if (r.ok) onDelete()
  }

  const url = `/admin/collection/${props.id}`

  return (
    <div className="collection col-3">
        <div className="collection-container w-100">
            <p>{props.coleccion}<FaRegTrashAlt onClick={hdlDelete} className='icon-right' /> </p>
            <p>{props.controlador}</p>
              <Link to={url}><p>{props.handle}</p></Link>
        </div>
    </div>
  )
}

export default Collection
