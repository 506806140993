import React, { useEffect, useState } from 'react'
import './ShippingWith.css'
import Shipping from './Shipping'
import { fetch_shipping, getLocal } from '../../../Apis/Api'
import useShop from '../../../Hooks/useShop'

const ShippingWith = ({onSelect}) => {
    const {select_cp} = useShop()
    const [selected, setSelected] = useState(getLocal('shipping'))
    const [lista, setLista] = useState([])
    const tema = getLocal('tema')
    const hdlSelect = (e) => {
        setSelected(e)
        onSelect(e)
    }
    const fetch_data = async () =>  {
        const cp = select_cp ? select_cp : getLocal('address').cp;
        setLista(await fetch_shipping(cp))
    }
    useEffect(()=>{ fetch_data() },[select_cp])

    return (
    <div className={'shippingwith row back-'+tema }>
        <h1>Método de Envío</h1>
        {
            lista.map((e,i)=>{
                return <Shipping  onSelect={hdlSelect} selected={selected} data={e} key={i} />
            })
        }
      
    </div>
  )
}

export default ShippingWith
