import React from 'react'
import './CSS/Account.css'
import AccountInfo from '../Components/AccountInfo/AccountInfo';

const Account = () => {
  return (
    <div className='account row'>
        <AccountInfo />
    </div>
  )
}

export default Account
