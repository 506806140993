import React from 'react'
import { Link } from 'react-router-dom';
import './Item.css'


const Item = (props) => {
  if (!props.data) return <></>;
  let precio=0;
  let oferta=0;
  let imagen='';
  if (props.data.detalle) {    
    for (let i = 0; i < props.data.detalle.length; i++) {
      const e = props.data.detalle[i];
      precio = e.precio;
      oferta = e.oferta;
      imagen= e.recursos.imagen[0].url || '';   
      break;
    }
  }  
  if (precio<oferta) oferta=precio;

  return (<>
    <div className='hide-sm item col-3'>
      <Link to={`/product/${props.data.handle}`}> 
        <img src={imagen} onClick={window.scrollTo(0,0)} alt="" />
      </Link>
        <p>{props.data.nombre}</p>
        <div className="item-prices">
            <div className="item-price-new">
                ${precio}
            </div>
            <div className="item-price-old">
                ${oferta}
            </div>  
        </div>      
    </div>

    <div className='only-sm item col-3'>
      <Link to={`/product/${props.data.handle}`}> 
        <img src={imagen} onClick={window.scrollTo(0,0)} alt="" />
      </Link>
        <p style={{fontSize:"2.2rem"}}>{props.data.nombre}</p>
        <div className="item-prices">
            <div style={{fontSize:"2rem"}} className="item-price-new">
                ${precio}
            </div>
            <div style={{fontSize:"2rem", marginLeft:"1rem"}} className="item-price-old">
                ${oferta}
            </div>  
        </div>      
        <hr />
    </div>
    </>
  )
}

export default Item
