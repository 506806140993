import React, { useState } from 'react'
import './CSS/Login.css'
import { Link } from 'react-router-dom'
import Message from '../Components/Message/Message'
import useAuth from '../Hooks/useAuth'
import { FormRequest, AuthRequest } from '../Apis/Axios'
import { getLocal } from '../Apis/Api'

const Login = () => {   
  const [username, setUsuario] = useState("");
  const [password, setPassword] = useState("");       
  const [fail, setFail] = useState("");
  const tema = getLocal('tema')
  const Auth = useAuth()
  const [logged, setLogged] = useState(false);
  
   const handleSubmit = async (e) => {
      e.preventDefault()
      setFail("")
      if (!username) {
        setFail("Se requiere el usuario para continuar")
        return null;
      }
      if (!password) {
        setFail("Se requiere el password para continuar")
        return null;
      }

      const r = FormRequest()
      const headers = {accept: 'application/json', "Content-Type": 'application/x-www-form-urlencoded'}
      const options = {body:{grant_type:'password',username, password}, headers }    
      const res = await r.post('token', options)
      if (res.ok) {
        const me = AuthRequest(res.data) 
        const usr = await me.fetch('usuario/me')
        if (usr.ok) {
          res.data.x_token=usr.data.uuid;
          res.data.user = usr.data
          Auth.Login(res.data)            
          setLogged(true)
        } else setFail(usr.data)
      } else {
          setFail(res.data)
      }
  };          
    
   const handleChange= (evt) => {
    const {name, value} = evt.target;
      if (name==='email') setUsuario(value);
      if (name==='password') setPassword(value);
   }
   
   if (logged) {
    window.location.href = '/';
   }


  return (
    <div className={'row back-' + tema}>
      <div className="login-container col-6">
        <div className="col-12">
          <h1>Bienvenido de vuelta:</h1>
        </div>
      <form onSubmit={handleSubmit}>
        <div className="login-fields col-10">
          <input required onChange={handleChange} name="email" type="email" value={username}  placeholder='Dirección de correo'/>
          <input required onChange={handleChange} name="password" type="password" value={password} placeholder='Password'/>
        </div>

        <button className={'btn-lg btn-' + tema} type="submit" onClick={handleSubmit}>Continue</button>
      </form>
        <p className="login-login">
          ¿Aún no tienes cuenta?<Link to='/signup'><span>Regístrate aquí</span></Link>
        </p>
        <Message tipo='error' mensaje={fail} />
      </div>
    </div>
  )
}

export default Login
