import React, {useState} from 'react'
import './MainImage.css'
import { coleccion, draw, get_image } from '../../../Apis/Coleccion'
import { useEffect } from 'react'

const MainImage = ({reload,index, refresh}) => {
  const [imagen, setImagen] = useState("")

  const draw_image = async (last=false) => {
        const im = await draw(coleccion.value, index, null, last)
        if (im) setImagen(im)
        else setImagen(get_image(index))
  }

  refresh(draw_image)
  useEffect(()=>{
    if (reload<0) draw_image(true)
    else draw_image()
  },[index, reload])

  
  return (
    <div id="main-image" className="mainimage w-80" >
        <img  src={imagen} alt="" id="current-image" />        
    </div>
  )
}

export default MainImage;
