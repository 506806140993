import React, { useState } from 'react' 
import './NewsLetter.css'
import { getLocal } from '../../Apis/Api'
import { PublicRequest } from '../../Apis/Axios'

const NewsLetter = () => {
  const tema = getLocal('tema')
  const tienda = getLocal('tienda')

  const [email, setEmail] = useState("")

  const hdlClick =async (e) => {
    if (!email) return;
    const url =  `boletin/${tienda}`;
    const body = {email:email};
    const r = PublicRequest()
    const res = await r.post(url, {body})
    if (res.ok) {
      setEmail("")
    } else {
      alert(res.data.detail);
    }
  }

  const hdlChange = (e) => {
    setEmail(e.target.value)
  }

  return (
    <div className={'news-letter hero-'+tema}>
      <h1>Recibe las mejores promociones en tu correo</h1>
      <p>Suscríbete y mantente al día</p>
      <div>
        <input onChange={hdlChange} type="email"  placeholder='Your email address' value={email}/>
        <button onClick={hdlClick} className={'btn btn-'+tema} >Subscribir</button>
      </div>
    </div>
  )
}

export default NewsLetter
