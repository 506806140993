import React, { useEffect, useState } from 'react'
import './Popular.css'
import Item from '../Item/Item'
import { get_collection } from '../../Apis/Api'

const Popular = () => {
  const [data_product, setItems] = useState([]);
  useEffect(()=>{
    const fetch_data = async () => {
        const data= await get_collection('moda-actual');     
        if (data) setItems(data);
    }
    fetch_data();
  },[]);

  
  if (!data_product || !data_product.length) return <></>

  return (
    <div className='popular row'>
      <div className="col-12">
        <h1>MODA ACTUAL</h1>
        <hr />
      </div>
      <div className="popular-item row">
        {data_product?.map((item, i)=> {
            return <Item key={i} data={item}/>
        })}
      </div>
    </div>
  )
}

export default Popular
