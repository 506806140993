import React, {useState, useEffect} from 'react'
import './NewCollections.css'
import Item from '../Item/Item'
import { get_collection } from '../../Apis/Api'

const NewCollections = () => {

  const [data_product, setItems] = useState([]);
  useEffect(()=>{
    const fetch_data = async () => {
        const data= await get_collection('lo-mas-nuevo');
        if (data) setItems(data);
    }
    fetch_data();
  },[]);

  if (!data_product || !data_product.length) return <></>
  
  return (
    <div className='new-collections  row'>
      <div className="col-12">
        <h1>LO MÁS NUEVO...</h1>
        <hr />
      </div>
        <div className="collections row">
          {data_product.map((item,i)=>{
              return <Item key={i}  data={item} />
          })}
        </div>
      
    </div>
  )
}

export default NewCollections
