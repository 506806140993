import React, { createContext, useState, useEffect, useRef, useLayoutEffect } from 'react'
import { saveLocal, getLocal } from '../Apis/Api';

export const AuthContext = createContext(null);

const AuthContextProvider = (props)=>{
    const getAuth = () => {
        const tmp = getLocal('auth')
        if (tmp) return tmp
        return {};
    }

    const [auth, setAuth] = useState(getAuth());
    const [logged, setLogged] = useState(false);
    const [to_url, setFromUrl] = useState();
    const VISITANTE = 2;
    
    useEffect(()=>{
        const temp = getAuth()
        if (temp?.access_token) {
            setAuth({...temp})
        } else {
            setAuth({})
        }
    },[logged])

    
    const getStore = ()  => {
        return getLocal('store')
    }

    const getStoreName = () => {
        const store = getStore()
        if (!store) return '';
        return store.nombre
    }
        
    const isLogged = () => auth?.access_token

    const isStaff = () => {
        return (auth?.access_token && 
        (auth?.user?.rol?.rol_enabled === 1 && auth?.user?.rol.rol_id !== VISITANTE))
    }

    const Login= (data) => {
        saveLocal('auth', {...data})
        setLogged(true)
        setFromUrl('');
    }

    const Logout= () => {    
        saveLocal('auth', {})
        setLogged(false)        
        setAuth({})
    }

    const toUrl= (to) => {        
        setFromUrl(to);
    }
   
    const contextValue = {getAuth, Login, Logout, isLogged, to_url, toUrl, 
        isStaff, getStore, getStoreName };

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    )
}


export default AuthContextProvider;