import React, { useState } from 'react'
import './CollectionImage.css'
import { coleccion, get_stock } from '../../../Apis/Coleccion'

const CollectionImage = ({onSelect, stock}) => {    
    const [mapa, setMapa] = useState(coleccion.value.custom?.keys??[])
    if (!stock) return <></>
    const hdlSelect = (e) => {
        const idx = parseInt(e.target.dataset.orden);
        const i = coleccion.value?.custom?.keys.indexOf(idx)
        if (i===-1) coleccion.value?.custom?.keys.push(idx);
        else coleccion.value?.custom?.keys.splice(i,1);
        setMapa([...coleccion.value.custom.keys])
        onSelect([...coleccion.value.custom.keys])
    }

  return (
    <div className='collection-image row'>
      {
        stock?.recursos && stock.recursos.imagen.length ?
        stock.recursos.imagen.map((e,i) => {
            const idx =  mapa.indexOf(e.orden);
            const clase = idx!==-1?"collection-selected w-15":"collection-image w-15"
            return (
                <div key={i} className={clase}>
                    <img onClick={hdlSelect} src={e.url} data-orden={e.orden} alt="" />
                </div>
            )
        })        
        : <></>
      }
    </div>
  )
}

export default CollectionImage
