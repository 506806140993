import React, { useState } from 'react'
import './OrderList.css'
import Order from './Order'
import { useEffect } from 'react'
import { AuthRequest } from '../../Apis/Axios'
import useAuth from '../../Hooks/useShop';
import { getLocal } from '../../Apis/Api'

const OrderList = ({me}) => {
    const [lista, setLista] = useState([])
    const [offset, setOffset] = useState(0)
    const [total, setTotal] = useState(0)
    const {getStore} = useAuth()
    const tema = getLocal('tema')
    const limit=12;

    const hdlPrevious = () => { 
        if (limit > offset) { setOffset(0); return null; }
        setOffset(offset-limit);
    }
    
    const hdlNext = () =>   { 
        if (total > limit + offset) {   setOffset(offset+limit); return null; }
    }

    const fetch_data = async () => {
        const r = AuthRequest()
        if (!me) return;
        const where = {usuario_id:`eq(${me?.id})`}
        const params = {offset, limit, sort_by:"id desc", where}
        const url = `pedido/${me.tienda_id}` 
        const res = await r.fetch(url, {params})

        if (res.ok) {
            setLista(res.data.rows)
            setTotal(res.data.total)
        } else  setLista(...[])
      }
    
    useEffect(()=>{
        fetch_data()
    },[offset])

    if (!me) return <></>


    const antes = () => {
        if (!offset) return <div className="w-20"></div>;
        <div className="w-20">
            <button onClick={hdlPrevious} className='btn-lg'>Previo</button>
        </div>
    }

    const despues = () => {
        if (total < limit + offset) return <div className="w-20"></div>;
        <div className="w-20">
            <button onClick={hdlNext} className='btn-lg'>Siguiente</button>
        </div>
    }


    const show = () => {
        <div className="row">
            <div className="w-20"></div>
            { antes () }
            <div className="w-20"></div>
            { despues() }
        </div>

    }

  return (
    <div className={'orderlist colbox back-'+tema}>
        <h1>Lista de Órdenes</h1>
        <div className='orderlist-container row'>
            {
                lista.map((e,i)=>{
                    return <div className='col-4' key={i}><Order data={e} /></div>
                })
            }
        </div>    
        { show() }
    </div>
  )
}

export default OrderList
