import { useLocation, Navigate, Outlet } from 'react-router-dom'
import useAuth from '../../Hooks/useAuth'

const RequireAuth = () => {
    const {isLogged, toUrl}  = useAuth();
    const location = useLocation();
 
    // if (!isLogged()) toUrl(location);
    return (
        isLogged()
            ? <Outlet />
            : <Navigate to="/login" state={{from:location}}  replace />
     )
}

export default RequireAuth
