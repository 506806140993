import React, { useEffect, useState } from 'react'
import './UserList.css'
import User from './User'
import '../../Components/RequireAuth/RequireStaff.css'
import { forbidden } from '../../Components/RequireAuth/RequireStaff'
import useAuth from '../../Hooks/useAuth'
import { AuthRequest } from '../../Apis/Axios'
import SearchBar from '../../Components/SearchBar/SearchBar'
import { Link } from 'react-router-dom'
import { getLocal } from '../../Apis/Api'


const UserList = () => {
    const {getStore, getStoreName} = useAuth()
    const [store, setStore] = useState(getStore())        
    const [lista, setLista] = useState([])
    const [roles, setRoles] = useState([])
    const [rol, setRol] = useState(2)
    const [msg, setMsg] = useState("")
    const [user, setUser] = useState({})
    const params = {where:`$field:like($filtro)`, sort_by:"$field", limit:10}
    const tema=getLocal('tema')

    const fetch_data = async (id)=>{    
        const res = AuthRequest()
        const url = `tienda/usuarios/${id}`
        const r = await res.fetch(url)
        if (r.ok) {
            setLista(r.data)
        } else setMsg(r.data)
     }
     const fetch_rol = async () => {
        const res = AuthRequest()
        const r = await res.fetch('utils/rol')
        if (r.ok) {
            setRoles(r.data.rows)
        } else setMsg(r.data)
     }

     useEffect(()=>{
        if (store) {
            fetch_data(store.id)
            fetch_rol()
        }
     },[])

  const hdlAdd = async () => {  
    if (user) {
        const res = AuthRequest()
        const url = `usuario/${store.id}/${user.id}/${rol}`;
        const r = await res.post(url) 
        if (r.ok) {
            fetch_data(store.id)
            setUser({})
        } 
    }
  }

  const hdlSelect = async (row) => { setUser(row)  }
  const hdlRol = async (e) => { setRol(e.target.value) }
  const hdlDelete = async () => fetch_data(store.id)

  if (!store) 
   return forbidden("No ha seleccionado la tienda","Es necesario seleccionar una tienda para xontinuar","/admin")

  return (
    <div className={'userlist row back-'+tema}> 
        <h1>Lista de Usuarios de {getStoreName()}</h1>      
        <div className="row">
            <div className="userlist-search">
                <label>Usuario:</label>
                <SearchBar holder="Correo del usuario" onSelect={hdlSelect} field="email" 
                    url="usuario"  params={params} />
                <select value={2} onChange={hdlRol}>
                    { roles.map((v,i)=>{ return <option key={i} value={v.id}>{v.rol}</option> })}
                </select>
                <button className={'btn btn-'+tema} onClick={hdlAdd} >Agregar</button>
            </div>
        </div>
        <div className="userlist-container row">
            {
                lista.map((elem,idx) => {
                    return <User store={store} onDelete={hdlDelete} props={elem} key={idx} />
                })
            }
        </div>
        <Link to="/admin">Volver Administrador</Link>
    </div>
  )
}

export default UserList
