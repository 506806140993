import React, { useState } from 'react'
import './ImageSizer.css'
import { coleccion, coleccion_imagen, get_quad } from '../../../Apis/Coleccion';
import { useEffect } from 'react';

const ImageSizer = ({title, index, reload, onPrint, onAdd}) => {
  const [quads, setQuads] = useState(coleccion.value?.custom ? coleccion.value?.custom.mapa[index] : [])
  const [quad, setQuad] = useState(-1)

  let timer = null;
  const hdlChange = (e) => {  
    e.preventDefault()
    const {name, value} = e.target;
    const q =  get_quad(quads, name)
    if (!q) return;
    q.url = value
    setQuads([...quads])
    coleccion.value.custom.mapa[index] = [...quads]
    if (timer) clearTimeout(timer)
    timer = setTimeout(async () => {
        onPrint();
    },1500);

  }

  const hdlSelect = (e) => {
    const value = e.target.value
    // setQuad(value)
    const q = get_quad(quads, value)    
    if (q && coleccion_imagen.value) {
      q.url = coleccion_imagen.value
      setQuads([...quads])
      coleccion.value.custom.mapa[index] = [...quads]
      onPrint()
    }
  }

  const hdlPreview = () => onPrint(true)
  const hdlAdd = (e) => {
    onAdd()    
    window.location = '/cart';
  }

  useEffect(()=>{
    if (coleccion.value?.custom) 
        setQuads(coleccion.value?.custom.mapa[index])
  },[index])

  return (
    <div className='imagesizer w-80'>
        <h4>{title}</h4>
        <p>Seleccione la imagen a imprimir y el área en la prenda donde desea colocarla.</p>
        <p>Coloque la personalización en las cajas de texto a continuación</p>
        <p>Aproximadamente 2 segundos después de no teclear, se actualizará el contenido</p>
        <div className='imagesizer-lineas'>
            { !quads ? <></>
              :  quads.map((q, idx)=> {
                if (q.tipo!=='texto') return null;
                const label = `Línea a imprimir ${q.nombre}`
                return (
                <input key={idx} name={q.nombre} value = {q.url} data-field={idx} type="text"
                  onChange={hdlChange} maxLength={q.largo} placeholder={label} />
                )}
            )}
            <hr />
        </div>

        <div className='imagesizer-images'>
            <p>Seleccione imagen, el cuadrante y presione colocar </p>            
            <select name="cuadrante" value={quad} onChange={hdlSelect} >
                <option key={-1} value={-1}>--- Seleccionar un Cuadrante ---</option>
            
            { !quads ? <></>
              :  quads.map((q, idx)=> {
                if (q.tipo==='texto') return null;
                return <option  key={idx} value={q.id}>{q.nombre}</option>
              }
            )}
            </select>
            <hr />
        </div>

        <button onClick={hdlPreview}  className="btn">Visualizar</button>
        &nbsp; &nbsp;&nbsp;&nbsp;
        <button onClick={hdlAdd}  className="btn">Agregar</button>
    </div>
  )
}

export default ImageSizer
