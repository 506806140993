import React from 'react'
import { pad } from '../../Apis/Api'
import { Link } from 'react-router-dom'
import { asLocal } from '../../Apis/Api'

const Order = ({data}) => {

  return (
    <div className='order wcolbox'>
        <p className='bold'><Link to={`/order/${data.uuid}`}>Folio: {pad(data.tienda_id,3)}-{pad(data.id,7)}</Link></p>
        <p className='bold'>{data.address?.alias}</p>
        <p>email:{data.email}</p>
        <p>Fecha:{asLocal(data.created_at)} </p>
        <p>Status:{data.estatus}</p>
        <p>Autorizado:{data.autorizado} </p>      
    </div>
  )
}

export default Order
