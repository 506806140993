import React from 'react'
import './Item.css'

const Item = ({data}) => {
  const {nombre, imagen, precio, oferta, cantidad} = data
  return (
    <div className='item-order row'>
      <div className="w-25 item-order-image">
          <img src={imagen} alt="" />
      </div>
      <div className="w-50">
        <p>{nombre}</p>
      </div>
      <div className="w-25">
        $ {(oferta ? oferta : precio)*cantidad}
      </div>
        
    </div>
  )
}

export default Item
