import React, { useEffect, useState } from 'react'
import './CustomerList.css'
import Customer from './Customer'
import '../../Components/RequireAuth/RequireStaff.css'
import { forbidden } from '../../Components/RequireAuth/RequireStaff'
import useAuth from '../../Hooks/useAuth'
import { AuthRequest } from '../../Apis/Axios'
import { Link } from 'react-router-dom'
import EntryMsg from '../../Components/EntryMsg/EntryMsg'
import { getLocal } from '../../Apis/Api'


const CustomerList = () => {
    const {getStore, getStoreName} = useAuth()
    const [store, setStore] = useState(getStore())        
    const [lista, setLista] = useState([])
    const [filtro, setFiltro] = useState("")
    const [msg, setMsg] = useState("")
    const tema = getLocal('tema')
    // const params = {where:`$field:like($filtro)`, sort_by:"$field", limit:10}

    const fetch_data = async (id)=>{    
        const res = AuthRequest()
        const filtrar = filtro ? filtro : '%'
        const url = `tienda/clientes/${id}/${filtrar}`
        const r = await res.fetch(url)
        if (r.ok) {
            setLista(r.data)
        } else setMsg(r.data)
     }

     useEffect(()=>{
        if (store) fetch_data(store.id)
    },[])

  const hdlSearch = () => fetch_data(store.id);
  const hdlFiltro = (e) => setFiltro(e.target.value);
  const hdlDelete = async () => fetch_data(store.id)

  if (!store) 
   return forbidden("No ha seleccionado la tienda","Es necesario seleccionar una tienda para xontinuar","/admin")

  return (
    <div className={'customerlist row back-'+tema}> 
        <h1>Lista de Clientes de {getStoreName()}</h1>      
        <div className="row">
            <div className="customerlist-search">
                <label>Cliente:</label>
                <EntryMsg name="filtro" value={filtro} holder="Correo de usuario" 
                    change={hdlFiltro} mensaje={msg} />
                <button className={'btn btn-'+tema} onClick={hdlSearch} >Filtrar</button>
            </div>
        </div>
        <div className="customerlist-container row">
            {
                lista.map((elem,idx) => {
                    return <Customer store={store} onDelete={hdlDelete} props={elem} key={idx} />
                })
            }
        </div>
        <Link to="/admin">Volver Administrador</Link>
    </div>
  )
}

export default CustomerList
