import React from 'react'
import './Size.css'

const Size = ({size, onClick, selected}) => {
    const cname = selected===size ?"size-selected":"size"

  return (
    <div className={cname}>
        <div onClick={onClick} data-size={size} >{size}</div>
    </div>
  )
}

export default Size
