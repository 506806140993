import React from 'react'
import './Hero.css'
import hand_icon from '../Assets/hand_icon.png'
import arrow_icon from '../Assets/arrow.png'
import hero_image from '../Assets/hero_image.png'
import { getLocal } from '../../Apis/Api'
import { Link } from 'react-router-dom'

const Hero = () => {
  const tema = getLocal('tema')
  return (
    <div className={'row hero-' + tema}>
      <div className="hero-left col-6">
        <h2>Lo más nuevo solamente!</h2>
        <div>
            <div className="hero-hand-icon">
                <p>Nuevas</p>
                <img src={hand_icon} alt="" />
            </div>
            <p>Colecciones</p>
            <p>Para Todos</p>
        </div>
        <Link to = '/category/lo-mas-nuevo'>
          <button className={'btn-'+tema}>Ver Ahora</button>
        </Link>
      </div>

      <div className="hero-right hide-sm col-5">
        <img src={hero_image} alt="" />
      </div>
    </div>
  )
}

export default Hero
