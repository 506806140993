import React from 'react'
import './Shipping.css'
import useShop from '../../../Hooks/useShop';
import { getLocal } from '../../../Apis/Api';

const Shipping = ({selected, data, onSelect}) => {
  const tema = getLocal('tema')
  const  {getShippingAmount} = useShop()
  if (!data) return <></>
  const clases = (selected && selected.id===data.id) ? 'shipping-order selected ' : 'shipping-order'
  const hdlSelect = () => onSelect(data)

  return (
    <div className={clases} onClick={hdlSelect}>
        <p className='bold'>{data.valor.nombre}</p>
        <p>{data.valor.descripcion}</p>
        <p className='flex-right bold' >${getShippingAmount(data)}</p>
    </div>
  )
}

export default Shipping
