import React, {useState} from 'react'
import './User.css'
import Message from '../Message/Message';
import useAuth from '../../Hooks/useAuth';
import { AuthRequest } from '../../Apis/Axios';
import EntryMsg from '../EntryMsg/EntryMsg';
import { getLocal } from '../../Apis/Api';

const User = (props) => {    
    const Auth = useAuth()    
    const me = Auth?.getAuth()?.user
    const tema = getLocal('tema')
    const [f_data, setFormData] = useState({
        nombre:me.nombre, genero:me.genero, nacimiento:me.nacimiento, 
        telefono:me.telefono, enabled:me.enabled
    })
    const [label, setLabel] = useState('Listo')
    const [mensaje, setMensaje] = useState('')

    const handleChange = (event) => {
        const {name, value} = event.target;
        let nuevo = {...f_data}
        nuevo[name] = value
        setLabel('Actualizar')
        setFormData({...nuevo})
    }

    const handleUpdate = async () => {
        setMensaje(null)        
        const r = AuthRequest()
        const res = await r.put(`usuario/${me.id}`, {body:f_data})
        if (res.ok) {
            const nuevo = {...Auth.getAuth()}
            nuevo.user = res.data
            Auth.Login(nuevo)
            setLabel("Listo")
        } else {
            setMensaje(res.data)
        }
    }

  return (
    <div className={'user col-6 back-'+tema}>
        <div className="user-container row">
            <div className="col-12">
                <h4>Hola, {f_data.nombre}</h4>
                <Message tipo='error' mensaje={mensaje} />
            </div>
            <div className="user-fields col-12">
                <EntryMsg name="nombre" value={f_data.nombre} msg={mensaje}
                    change={handleChange} required length={100} />
                <EntryMsg name="telefono" value={f_data.telefono} msg={mensaje}
                    change={handleChange} required length={15} holder="Celular"  />
                <EntryMsg name="genero" value={f_data.genero} msg={mensaje} type="select"
                    change={handleChange} required options={["masculino","femenino","n/a"]}/>
                <EntryMsg name="nacimiento" value={f_data.nacimiento} msg={mensaje} type="date"
                    change={handleChange} required holder="Fecha de Nacimiento" />
                <button className={'btn-md btn-'+tema} onClick={handleUpdate} >{label}</button>
            </div>
        </div>
    </div>
  )
}

export default User
