import React, { useEffect } from 'react'
import './CollectionHeader.css'
import SearchBar from '../../../Components/SearchBar/SearchBar';
import EntryMsg from '../../../Components/EntryMsg/EntryMsg';
import { useState } from 'react';
import { coleccion, coleccion_item, get_stock } from '../../../Apis/Coleccion';
import { fetch_prod } from '../../../Apis/Api';
import CollectionImage from './CollectionImage';

const CollectionHeader = ({ onSelect, onError, store, reload}) => {
    const [filtro, setFiltro] = useState("")
    const params = {where:`$field:like($filtro)`, sort_by:"$field", limit:10}
    const [data, setData] = useState({coleccion:coleccion.value.coleccion, etiquetas:coleccion.value.etiquetas})
    const [stock, setStock] = useState(get_stock())

    const load_prod = async (id) => {
        coleccion_item.value =  await fetch_prod(store.id, id, onError)         
        setFiltro(coleccion_item.value.nombre)
        setStock(get_stock())
    }
    
    const hdlSelect = async (item) => { 
        coleccion.value.custom.product_id=item.id
        await load_prod(item.id)    
    }

    const hdlChange = (e) =>{        
        const {name, value} = e.target;
        data[name] = value
        setData({...data})
        coleccion.value[name] = value
    }

    useEffect(()=>{
        if (reload) {
            load_prod(coleccion.value.custom.product_id);
        }
    },[])

    const url_item = `producto/${store.id}`

  return (
    <div className='collectionheader row'>                
        <div className="collectionform-input w-100">
            <EntryMsg name="coleccion" value={data.coleccion} length={120}  label="Coleccion" change={hdlChange} />
        </div>
        <div className="collectionform-input w-100">
            <EntryMsg name="etiquetas" value={data.etiquetas} length={500}  label="Etiquetas" change={hdlChange} />
        </div>
        <div className="collectionform-input w-80">
            <div className="col-12 collectionform-filtro">{filtro}</div>
            <div className="col-12 collectionform-filtro">
                <SearchBar holder="Producto Base" onSelect={hdlSelect} field="nombre" 
                    value={filtro} url={url_item}  params={params} />
            </div>
        </div>
        <CollectionImage onSelect={onSelect} stock={stock} />
    </div>
  )
}

export default CollectionHeader
