import React, {useState} from 'react'
import './OrderList.css'
import useAuth from '../../Hooks/useAuth'
import { forbidden } from '../../Components/RequireAuth/RequireStaff'
import EntryMsg from '../../Components/EntryMsg/EntryMsg'
import { AuthRequest } from '../../Apis/Axios'
import { Link } from 'react-router-dom'
import { asUTC, pad, asLocal, STATUS, getLocal } from '../../Apis/Api'
import { asMoney } from '../../Components/Order/Totales/Total'

const OrderList = () => {
    const {getStore, getStoreName} = useAuth()
    const [store, setStore] = useState(getStore())        
    const tema = getLocal('tema')
    const [lista, setLista] = useState([])
    const [sort_by, setSort] = useState("id desc")
    const [paginas, setPaginas] = useState(["Página 1"])
    const [pagina, setPagina] = useState(0)
    const [filtro, setFiltro] = useState({        
        hasta: new Date().toJSON().substring(0,10), 
        desde: new Date(new Date().setDate((new Date).getDate() - 30)).toJSON().substring(0,10), 
        email:"", status:"Todos", folio:0
    })
    const [msg, setMsg] = useState("")
    const params = {sort_by:sort_by, limit:10, enabled:0, 
        columns:':all,:total'}

    const hdlChange = (e) => {
        const {name, value} = e.target;
        filtro[name] = value;
        setFiltro({...filtro})
    }

    const hdlFiltro = async (offst) => {
        if (typeof offst === 'object' || !offst) offst=0;
        const res = AuthRequest()        
        params['offset'] = offst
        const n_desde = asUTC(`${filtro.desde} 00:00`).replace(' ','$T').replaceAll(':','#T')
        const n_hasta = asUTC(`${filtro.hasta} 23:59:59`).replace(' ','$T').replaceAll(':','#T')
        let where = `created_at:between(${n_desde},${n_hasta});`
        const rechazo = `${STATUS[3]},${STATUS[5]}`;
        const vigente = `${STATUS[0]},${STATUS[1]},${STATUS[2]},${STATUS[4]}`;
        if (filtro.email) where += `email:eq(${filtro.email});`
        if (filtro.folio) where += `id:eq(${filtro.folio});`
        if (filtro.status!=='Todos')
            if (filtro.status==='Rechazados') where += `estatus:in(${rechazo});`
            else if (filtro.status==='Vigentes') where += `estatus:in(${vigente});`
            else  where += `estatus:in(${filtro.status.toLowerCase()});`

        if (where) params['where'] = where
        const url = `pedido/${store.id}`
        const r = await res.fetch(url, {params})
        if (r.ok) {
            if (offst===0) {
                const pages = Math.floor(r.data.total / params.limit) + 1;
                const p = []
                for (let i = 0; i < pages; i++) {
                    p.push(`Página ${i+1}`)                
                }
                setPaginas(p)
            }
            setLista(r.data.rows)
        } else setMsg(r.data)

    }

    const hdlSort = (e) => setSort(e.target.value)
    const hdlPage = async (e) => {
        let value = e.target.value;
        setPagina(value)
        value = value.replace('Página ','')
        value = parseInt(value)-1;
        await hdlFiltro(parseInt(value)*params.limit)
    }

    if (!store) 
    return forbidden("No ha seleccionado la tienda","Es necesario seleccionar una tienda para xontinuar","/admin")
 
  return (
    <div className={'orderlist row bak-'+tema}>
      <h1>Relación de Órdenes {getStoreName()}</h1>  
      <div className="row orderlist-filter">
        <div className="w-33">
            <EntryMsg type="date" name="desde" holder="Desde" value={filtro.desde} change={hdlChange} msg={msg}/>
        </div>
        <div className="w-33">
            <EntryMsg type="date" name="hasta" holder="Hasta" value={filtro.hasta} change={hdlChange} msg={msg}/>
        </div>
        <div className="w-33">
            <EntryMsg type="number" name="folio" holder="Folio" value={filtro.folio} change={hdlChange} msg={msg}/>
        </div>

      </div>

      <div className="row orderlist-filter">
        <div className="w-50">
            <EntryMsg name="email" holder="Correo" value={filtro.email} change={hdlChange} msg={msg} />
        </div>
        <div className="w-50">
            <EntryMsg type="select" name="status" holder="Status" value={filtro.status} 
            change={hdlChange} msg={msg} 
            options={["Todos", "Rechazados", "Vigentes", ...[STATUS]]}/>
        </div>
        </div>
        <div className='row flex-right orderlist-button'>
            <EntryMsg type="select" name="sort_by" holder="Sort by" 
            value={sort_by} change={hdlSort} msg={msg} 
            options={["id desc","created_at desc","id asc","created_at asc"]}/>

            <EntryMsg type="select" name="paginas" holder="Page" 
            value={pagina} change={hdlPage} msg={msg} 
            options={paginas}/>

            <button onClick={hdlFiltro}  className={'btn btn-'+tema}>Filtrar</button>
        </div>

        <div className="row">
            <div className="col-12 orderlist-detail">
                <p className='bold w-10'>Folio</p>
                <p className='bold w-20'>Correo</p>
                <p className='bold w-15'>Creado</p>
                <p className='bold w-15'>Autorizado</p>
                <p className='bold w-10'>Estatus</p>
                <p className='bold w-15'>Pago</p>
                <p className='bold w-15 flex-right'>Total</p>

            </div>
            {

                lista.map((e,i)=>{
                    const url = `/admin/order/${e.uuid}`;
                    return (
                        <div className="orderlist-detail col-12" key={i} >
                            <p className='w-10'><Link to={url}>{pad(e.id,7)}</Link></p>
                            <p className='w-20'>{e.email}</p>
                            <p className='w-15'>{asLocal(e.created_at.replace('T',' ')).substring(2,16)}</p>
                            <p className='w-15'>{e.autorizado}</p>
                            <p className='w-10'>{e.estatus}</p>
                            <p className='w-15'>{e.payment.valor}</p>
                            <p className='w-15 flex-right'>${asMoney(e.total)}</p>
                        </div>
                    )
                })
            }
        </div>

        <Link to="/admin">Volver Administrador</Link>
    </div>
  )
}

export default OrderList 
