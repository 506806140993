import React from 'react'
import { coleccion, coleccion_actual } from '../../../Apis/Coleccion'
import { useState } from 'react'

const CollectionCanva = ({ reload, onSelect, selected}) => {
  const [actual, setActual] = useState(coleccion_actual.value)

  const hdlActual = (e) => {
    const o = parseInt(e.target.value.replace('Imagen ',''))        
    coleccion_actual.value = o
    onSelect(o)
    setActual(o)
  }

  return (
    <div className='collection-canva row'>
      {      
        <div className="collectionform-input w-100">
                <span className='bold w-50'>Actual:</span>
                <select className='w-50' value={actual} name="orden" onChange={hdlActual}>
                    <option key={0} value={0}>{0}</option>
                    {                        
                        selected.map((e,i)=>{
                            return (
                                <option key={i} value={e}>Imagen {e}</option>
                            )
                        })
                    }
                </select>
        </div>
      }
    </div>
  )
}

export default CollectionCanva ;