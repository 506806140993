import React from 'react'
import './OrderHeader.css'
import { pad } from '../../Apis/Api'
import { asLocal } from '../../Apis/Api'

const OrderHeader = ({data}) => {
  const p = {...data}
  return (
    <div className='orderheader row'>
      <div className="orderheader-container col-12">
        <div className="w-60">
          <p><span className='bold'>Entregar a:</span>{p.address?.contacto}</p>
          <p>{p.address?.calle} Ext: {p.address?.no_exterior} Int: {p.address?.no_interior}</p>
          <p>{p.address?.colonia}, CP:{p.address?.cp}</p>
          <p>{p.address?.zona.municipio}, {p.address?.zona.estado}; {p.address?.zona.pis}</p>
          <p><span className='bold'>Envio:</span> {p.shipping.valor}</p>
        </div>
        <div className="w-40">
          <p><span className='bold'>Folio:</span> {pad(p.tienda_id,3)}-{pad(p.id,7)}</p>
          <p><span className='bold'>Fecha:</span> {asLocal(p.created_at)}</p>
          <p><span className='bold'>Estatus:</span> {p.estatus}</p>
          <p><span className='bold'>Autorizado:</span> {asLocal(p.autorizado)}</p>
          <p><span className='bold'>Pago:</span> {p.payment.valor}</p>
        </div>
      </div>
      <hr />
    </div>
  )
}

export default OrderHeader
