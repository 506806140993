import React from 'react'
import './CollectionContainer.css'
import EntryMsg from '../../../Components/EntryMsg/EntryMsg'
import { useState, useEffect } from 'react'
import CollectionCuadrante from './CollectionCuadrante'
import { coleccion, get_image, draw } from '../../../Apis/Coleccion'

const CollectionContainer = ({reload, actual}) => {
    const limpio = {tipo:"imagen", nombre:"", x:0, y:0, w:200, h:200, largo:0}
    const [cuadrante, setCuadrante ] =  useState({...limpio})
    const [data, setData] = useState({...coleccion.value})    
    const [imagen, setImagen] = useState("")

    const recover = async() => {    
        const r = await draw(data, actual, cuadrante, false)
        if (r) {            
            try {
                setImagen(r)
            } catch (error) {
                setImagen(get_image(actual))
                console.log(error);                
            }
        }
     }

    useEffect(()=>{
        const im = get_image(actual);
        setImagen(im)
        if (!coleccion.value?.custom?.mapa || !coleccion.value?.custom?.mapa[actual]) 
            coleccion.value.custom.mapa[actual]=[]

        setData({...coleccion.value})
    },[actual])

    if (!actual) return <></>

    const hdlData = (e) => {
        const {name, value} = e.target;
        data.custom[name] = value
        coleccion.value.custom[name] = value
        setData({...data})

    }

    const hdlCuadrante = (e) => {
        const {name, value} = e.target;
        cuadrante[name] = value
        if (name==='w') cuadrante['h'] = value
        if (name==='w' && cuadrante.tipo.toLocaleLowerCase() ==='texto') cuadrante['h'] = 50
        setCuadrante({...cuadrante})
    }

    const findQuad = (nombre) => {
        let idx=-1
        if (!data.custom?.mapa[actual]) return idx;
        for (let i = 0; i < data.custom.mapa[actual].length; i++) {
            const m = data.custom.mapa[actual][i];
            if (m.nombre.toLowerCase()===nombre.toLowerCase()) return i
        }
        return idx
    }

    const loadQuad = (nombre) => {
        const idx = findQuad(nombre)
        if (idx>=0) {
            const q = data.custom.mapa[actual][idx]
            setCuadrante(q)
        }
    }

    const hdlRemove = (nombre) => {
        const idx = findQuad(nombre)
        if (idx>=0) {
            data.custom?.mapa[actual].splice(idx,1)
            setData({...data})
        }
    }

    
  const hdlPreview = async (e) => { 
    await recover()
  }

  const hdlAddCuad = async (e) => {
    if (!cuadrante.nombre) {
        alert("Es necesario el nombre para continuar")
        return false
    }
    if (!cuadrante.w) {
        alert("Es necesario el ancho para continuar")
        return false
    }
    if (cuadrante.tipo==='texto' && !cuadrante.largo) {
        alert("Se requiere largo de caracteres para continuar")
        return false
    }

    const idx = findQuad(cuadrante.nombre)
    if (idx>=0) {
        data.custom.mapa[actual][idx] = {...cuadrante}
        // coleccion.value.custom.mapa[actual][idx] = {...cuadrante}
        setData({...data})
    } else {
        data.custom.mapa[actual].push({...cuadrante});
        // coleccion.value.custom.mapa[actual].push({...cuadrante});
        setData({...data})
    }    
    setCuadrante({...limpio})
  }

  const hdlAddClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!actual) return false;
    const w = cuadrante.w;
    if (cuadrante.tipo.toLocaleLowerCase() ==='texto') cuadrante.h = 50
    const h = cuadrante.h;
    cuadrante['x'] = e.nativeEvent.offsetX-(w/2)
    cuadrante['y'] = e.nativeEvent.offsetY-(h/2)
    setCuadrante({...cuadrante})
    await recover()
  }


  return (
    <div className='collexction-container row'>
      <div  className="row">
            <img id="current-img"  onClick={hdlAddClick} src={imagen} alt="" />
      </div>
        <div className="row">
            <div className="collectionform-input w-33">
                <EntryMsg name="color" value={data.custom.color} length={20}  
                    label=" Color" change={hdlData} />
            </div>
            <div className="collectionform-input w-33">
                <EntryMsg name="border" value={data.custom.border} length={20}  
                    label=" Borde" change={hdlData} />
            </div>
            <div className="collectionform-input w-5"></div>
            <div className="collectionform-input w-20">
                <span className='bold'>Tipo:</span>
                <select value={cuadrante.tipo} name="tipo" onChange={hdlCuadrante}>
                    <option value="imagen">Imagen</option>
                    <option value="texto">Texto</option>
                </select>
            </div>
            <div className="collectionform-input w-20">
                <EntryMsg name="nombre" value={cuadrante.nombre}
                    label="Nombre" change={hdlCuadrante} />
            </div>
            <div className="collectionform-input w-5"></div>
            <div className="collectionform-input w-10">
                <EntryMsg type="number" name="largo" value={cuadrante.largo}
                    label="Largo" change={hdlCuadrante}
                />
            </div> 
            <div className="collectionform-input w-10">
                <EntryMsg type="number" name="x" value={cuadrante.x}
                    label="X" change={hdlCuadrante} />
            </div>
            <div className="collectionform-input w-10">
                <EntryMsg type="number" name="y" value={cuadrante.y}
                    label="Y" change={hdlCuadrante} />                
            </div>
            <div className="collectionform-input w-10">
                <EntryMsg type="number" name="w" value={cuadrante.w}
                    label="W" change={hdlCuadrante}
                />
            </div> 
            <div className="collectionform-input w-5"></div>
            <div className="collectionform-input w-10">
                <button onClick={hdlAddCuad} className='btn'>Agregar</button>
                
            </div> 
            <div className="collectionform-input w-5"></div>
            <div className="collectionform-input w-10">
                <button onClick={hdlPreview} className='btn'>Ver</button>
            </div>            
            <div className="row">
                {
                    data.custom?.mapa[actual]?.map((e,i)=>{ 
                        return <CollectionCuadrante key={i} onSelect={loadQuad} onDelete={hdlRemove} data={e} /> 
                    }) 
                }                
            </div>
        </div>
    </div>
  )
}

export default CollectionContainer 
