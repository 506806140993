import React, { useState } from 'react'
import './AddressInfo.css'
import { AuthRequest } from '../../Apis/Axios';
import Message from '../Message/Message';
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";

const AddressInfo = (props) => {
    const {data,onUpdate} = props;
  const [msg,setMsg] = useState("")

   const show = () => {
    if (data.actual) return `[DEFAULT]:`
    return ""
   }

   const hdlRemove = async (evt) => {
    const id = evt.target.dataset.id;
    if (!id) return null;
    const r = AuthRequest()
    const res = await r.delete(`usuario/direccion/${id}`)
    if (res.ok) { 
      onUpdate();
    } else {
      setMsg(res.data)
    }
  }
  
  const hdlDefault = async (evt) => {
    const id = evt.target.dataset.id;
    const url = "usuario/direccion/"+id;
    if (!id) return null;
    const r = AuthRequest()
    const res = await r.put(url)
    if (res.ok) { 
      onUpdate();
    } else {      
      setMsg(res.data)
    }
   }

  return (
    <div className='addressinfo'>
      <Message tipo="error" msg={msg} />
      <div> <span>{show()}  {data.alias}</span>
          <FaRegTrashAlt data-id={data.id} onClick={hdlRemove} className='icon-right' />
      </div>
      <div id="addressinfo-detail">
        <div>{data.calle}  Int:{data.no_interior} Ext:{data.no_exterior}</div>
        <div>{data.colonia}  CP:{data.cp}</div>
        <div>{data.zona.municipio}, {data.zona.estado}; {data.zona.pais}</div>
      </div>

      <hr/>
      <div className='bold' >{data.contacto}  Tel:{data.telefono} 
          <FaRegCheckCircle data-id={data.id} onClick={hdlDefault} className="icon-right"/> 
      </div>      
    </div>
  )
}

export default AddressInfo
