import React, { useState } from 'react'
import './PaymentForm.css'
import EntryMsg from '../../Components/EntryMsg/EntryMsg';
import Message from '../../Components/Message/Message';
import { AuthRequest } from '../../Apis/Axios';
import useAuth from '../../Hooks/useAuth';
import { getLocal } from '../../Apis/Api';

const PaymentForm = ({onRefresh, props}) => {    
    const valor = JSON.parse(props.valor)  
    const {getStore} = useAuth()
    const [label, setLabel] = useState(props.id?'Actualizar':'Agregar')
    const [msg, setMsg] = useState('')
    const [data, setData] = useState(valor);
    const [clave, setClave] = useState(props.clave);
    const [store, setStore] = useState(getStore())
    const tema = getLocal('tema')

    const hdlChange = (e) => {
        setMsg("")
        const {name, value} = e.target;        
        data[name] = value
        setData({...data})
    }

    const hdlClave = (e) => setClave(e.target.value)
    const hdlAdd = async () => {
        const res = AuthRequest()
        const url = `utils/setting/${store.id}`
        let r = null;
        props.valor = JSON.stringify(data)
        if (!props.id) {
            props.clave = clave
            r = await res.post(url, {body:props})
        } else  r = await res.put(url, {body:props})
        if (r.ok) {
            onRefresh()
        } else setMsg(r.data)
    }

  return (
    <div className='paymentform'>
        <div className="paymentform-container row">
        <EntryMsg msg={msg} change={hdlClave} name="clave" value = {clave} length={20} required label="Clave"/>
        <EntryMsg msg={msg} change={hdlChange} name="nombre" value = {data.nombre} length={100} required label="Nombre"/>
        <EntryMsg msg={msg} change={hdlChange} name="descripcion" value = {data.descripcion} length={500} required 
          type="area" cols={100}  label="Descripcion"/>
        <EntryMsg msg={msg} change={hdlChange} name="logo" value = {data.logo} length={500} required label="Logo"/>
        <EntryMsg msg={msg} change={hdlChange} name="public" value = {data.public} length={200} required label="Public"/>
        <EntryMsg msg={msg} change={hdlChange} name="access_token" value = {data.access_token} length={200} required label="Access Token"/>
        <EntryMsg msg={msg} change={hdlChange} name="web_hook" value = {data.web_hook} length={500} required label="Web Hook"/>
        <EntryMsg msg={msg} change={hdlChange} name="status_code" value = {data.status_code} length={20} required label="Status Code"/>
        </div>
        <div className="flex-center">
          <button onClick={hdlAdd} className={'btn-lg btn-'+tema}>{label}</button>
            <Message msg={msg} tipo="error" />
        </div>
        <hr />
    </div>
  )
}

export default PaymentForm
