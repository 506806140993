import React, { useState, useEffect } from 'react'
import './Footer.css'
import { Link } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa";
import { FaPinterestSquare } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import Settings from '../../Apis/Api'

// Como cargar iconos
// https://react-icons.github.io/react-icons/search/#q=pinterest

const Footer = () => {

    const [logo, setLogo] = useState("https://c.tenor.com/0iK9a1WkT40AAAAC/loading-white.gif")
    const [alias, setAlias] = useState("")
  
    useEffect(()=>{
      const fetch_data = async () => {
          const data= await Settings('config');
          if (data) {
              setLogo(data.logo);
              setAlias(data.alias);
          }        
      }
      fetch_data();
    },[]);
  
  return (
    <div className='footer'>
        <div className="footer-logo">
            <img src={logo} style={{maxWidth:"100px"}} alt="" />
            <p>{alias?.toUpperCase()}</p>                        
        </div>      
        <ul className="footer-links">
            <li><Link to='/account'>Mi Cuenta</Link></li>
            <li><Link to='/cart'>Mi Carrito</Link></li>
            <li><Link to='/admin'>Administrar</Link></li>
            <li><Link to='/page/about-us'>Acerca de</Link></li>
            <li><Link to='/page/contact-us'>Contacto</Link></li>
        </ul>
        <div className="footer-social-icons">
            <div className="footer-icons-container">
                <FaInstagram/>
            </div>
            <div className="footer-icons-container">
                <FaPinterestSquare/>
            </div>            
            <div className="footer-icons-container">
                <FaWhatsapp />
            </div>            
            <div className="footer-icons-container">
                <FaFacebook />
            </div>            
            <div className="footer-icons-container">
                <FaSquareXTwitter />
            </div>            

        </div>
        <div className="footer-copyright">
            <hr />
            <p>Copyright @ 2023 All right reserved</p>
        </div>
    </div>
  )
}

export default Footer
