import React, { useEffect, useState } from 'react'
import './Header.css'

const Header = ({cols, onSelect, selected}) => {
  const [col, setCol] = useState(selected)
  const hdlSelect = (e) =>     {
      setCol(Number(e.target.value))
      onSelect(Number(e.target.value))
    }
    
    useEffect(()=>{
        setCol(selected)
        onSelect(Number(selected))
    },[selected])
    
  if (!cols || !cols.length) return <></>
  
  return (
    <div className='personaliza-header row'>
        <div className="w-40">Colección</div>
        <div className="w-60">
            <select name="coleccion" value={col} onChange={hdlSelect} >
                <option key={0} value={0}>--- Seleccionar una Opción ---</option>
                {                    
                    cols.map((e,i) => {
                        return <option  key={e.id} value={e.id}>{e.coleccion}</option>
                    })

                }
            </select>
        </div>
      
    </div>
  )
}

export default Header
