import React, { useState } from 'react'
import './SearchBar.css'
import EntryMsg from '../EntryMsg/EntryMsg';
import { AuthRequest } from '../../Apis/Axios';

const SearchBar = (props) => {
    const {holder,  onSelect, field, value, url, params} = props;
    const [show, setShow] = useState(false);
    const [filtro, setFiltro] = useState(value)
    const [lista, setLista] = useState([])

    let timer = null;
    const hdlUpdate = (e) => {
        const search = e.target.value
        setFiltro(search)
        if (timer) clearTimeout(timer)
        timer = setTimeout(async () => {
            if (search) {
                const res = AuthRequest()
                const param = {}
                for (const key in params) {
                    let v = (params[key]).toString()
                    param[key]=v.replace('$field',field).replace('$filtro', search)
                }
                const r = await res.fetch(url, {params:param})
                if (r.ok) {                
                    setShow(true)
                    setLista(r.data.rows)
                }
            }
        },500);
    }

    const hdlClic = (e) => {
        const idx = e.target.dataset.idx;
        const current = lista[idx]
        onSelect(current);
        setFiltro(current[field])
        setShow(false)
    }

  return (
    <div className='searchbar row'>
        <EntryMsg length={50} holder={holder} name="filtro" value={filtro} change={hdlUpdate} />
        <div className="col-12 searchbar-list">
        {
            lista.map((elem, idx)=>{
                if (show)
                return <div onClick={hdlClic} data-idx={idx} key={idx}>{elem[field]}</div>
            })   
        }
        </div>
    </div>
  )
}

export default SearchBar
