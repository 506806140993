import React, { useState } from 'react'
import './Tienda.css'
import { FaCloud } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import EntryMsg from '../../Components/EntryMsg/EntryMsg';
import { AuthRequest } from '../../Apis/Axios';
import { Link } from 'react-router-dom';

const Tienda = ({search, props}) => {
    const [accion, setAccion] = useState('')
    const [msg, setMsg] = useState('')
    const [data, setData] = useState({...props})
    const clases = props.enabled ? "tienda col-4" : "tienda col-3 inactive"

    const hdlEdit =  () => { setAccion('edit')   }
    const hdlChange = (e) => {
        const {name, value} = e.target;
        data[name] = value
        setData({...data})
    }

    const hdlUpdate = async () => {
        const res = AuthRequest()
        const body = {nombre:data.nombre, codigo:data.codigo, enabled:data.enabled}
        const r = await res.put(`tienda/${data.id}`, {body})
        if (r.ok) {
            setAccion('')
            search()
        } else setMsg(r.data)
    }

    const hdlCancel =  () => setAccion('')
    const hdlCheck = (e) => {
        data.enabled = e.target.checked ? 1 : 0
        setData({...data})       
    }

    if (accion==='edit') {
        return (
            <div className="tienda col-4 editing">
                <EntryMsg name="nombre" msg={msg} length={100} required change={hdlChange} value={data.nombre} />
                <EntryMsg name="codigo" msg={msg} length={10} required change={hdlChange} value={data.codigo} />
                <div className='tienda-enabled'>
                    <label htmlFor="enabled">Habilitado</label>
                    <input onChange={hdlCheck} type="checkbox" name="enabled" checked={data.enabled?true:false}  />
                </div>
                    <button onClick={hdlUpdate} >Actualizar</button>
                <button onClick={hdlCancel}>Cancelar</button>
            </div>
        )
    }


    const cloud = `/dominio/${data.id}`
    return (
    <div className={clases}>
      <p>{data.nombre}<Link to={cloud}><FaCloud data-id={data.id} className='cloud' /></Link>
      </p>
      <p>{data.codigo}<FaRegEdit data-id={data.id} onClick={hdlEdit} className='edit' /></p>      

    </div>
  )
}

export default Tienda
